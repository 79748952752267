import React from "react";

// MUI Components Import
import { styled, Container } from "@mui/material";

// Components Import
import About from "./About";
import Links from "./Links";
import Contact from "./Contact";
import Socials from "./Socials";
import Payments from "./Payments";
import Partners from "./Partners";
import Copyright from "./Copyright";
import FooterTextEditorOne from "./FooterTextEditorOne";
import Heading from "../../../components/Heading";
// import FooterTextEditorTwo from "./Components/FooterTextEditorTwo";

const headerData = {
  heading: "Manage Marketplace Footer",
  subheading: "",
};

function Footer() {
  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        <About />
        <Links />
        <Contact />
        <Socials />
        <Payments />
        <Partners />
        <Copyright />
        <FooterTextEditorOne />
        {/* <FooterTextEditorTwo />s */}
      </Wrapper>
    </>
  );
}

// Stryled Components

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default Footer;
 