import { createContext, useContext, useState } from "react";

const UserThemeContext = createContext();

export const useUserThemeContext = () => {
    return useContext(UserThemeContext);
};

export const UserThemeProvider = ({ children }) => {

    const [navTheme, setNavTheme] = useState("#d49e6e");
    const [bgTheme, setBgTheme] = useState();
    const [textTheme, setTextTheme] = useState();

    const updateNavTheme = (navColor) => {
        setNavTheme(navColor)
    }

    const updateBgTheme = (BgColor) => {
        setBgTheme(BgColor)
    }

    const updateTextTheme = (TextColor) => {
        setTextTheme(TextColor)
    }

    return (
        <UserThemeContext.Provider value={{ navTheme, bgTheme, textTheme, updateNavTheme, updateBgTheme, updateTextTheme }}>
            {children}
        </UserThemeContext.Provider>
    );
};
