import {
	Container,
	Box,
	Button,
	Grid,
	Tooltip,
	Pagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Heading from "../../components/Heading";
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";
import { SubHeading } from "../../../../components/Heading";
import {
	useCreateBlogMutation,
	useDeleteBlogMutation,
	useGetBlogsQuery,
	useUpdateBlogMutation,
} from "../../../../redux/api/blogApiSlice";
import { BarLoader, MoonLoader } from "react-spinners";
import { toast } from "react-toastify";
import MUITable, {
	StyledTableCell,
	StyledTableRow,
} from "../../../../components/MUITable";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import { truncateString } from "../../../../utils";
import TextEditor from "../../../../components/TextEditor";
import { useGetHeaderQuery } from "../../../../redux/api/cmsApiSlice";
import axios from "axios";
import { baseUrl } from "../../../../constants/api";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import Loader from "../../../Loaders/Loader";

const headerData = {
	heading: "Create Static Pages",
	subheading:
		"Add/Remove, pages that describe your platform, such as the Return Policy, Privacy Policy, Help & Support, Become a Seller, and more, as you see fit.",
};
const tableHead = [
	"#",
	"Title (Eng)",
	"Content (Eng)",
	"Title (Arabic)",
	"Content (Arabic)",
	"Actions",
];

const StaticPages = () => {
	const token = localStorage.getItem("user-token");
	const { loading: contextLoading, handleLoader } = useLoaderContext();

	const [page, setPage] = useState(1);
	const RowsPerPage = 5;
	const [editStaticPage, setEditStaticPage] = useState(null);
	const [selectedStaticPage, setSelectedStaticPage] = useState("");
	const [staticPageTitle, setStaticPageTitle] = useState("");
	const [staticPageContent, setStaticPageContent] = useState("");
	const [arabicTitle, setArabicTitle] = useState("");
	const [arabicContent, setArabicContent] = useState("");
	const [headers, setHeaders] = useState([]);
	const [secondLoader, setSecondLoader] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingTwo, setLoadingTwo] = useState(false);

	// todo: GET ALL HEADERS API CALL
	const { data: getAllHeaders, refetch: refetchHeaders } = useGetHeaderQuery(
		{}
	);

	// todo: GET ALL PAGES API CALL
	const {
		data: getAllPages,
		isLoading: getAllPagesLoading,
		isSuccess,
	} = useGetBlogsQuery({ limit: RowsPerPage, offset: page, staticPage: true });

	// console.log("getAllPages", getAllPages?.results);

	useEffect(() => {
		refetchHeaders();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (getAllHeaders && getAllPages) {
			const allHeaders = getAllHeaders?.headers || [];

			// Update headers based on pages
			const updatedHeaders = allHeaders
				.map((header) => {
					const page = getAllPages?.results.find(
						(page) => page.slug === header.route
					);
					return page ? { ...header, active: header.active } : null;
				})
				.filter((header) => header !== null);

			// Combine all headers and new pages
			const combinedHeaders = [...updatedHeaders];

			// Add the first two headers from original headers to the beginning
			const firstHeader = allHeaders[0];
			const secondHeader = allHeaders[1];

			setHeaders([firstHeader, secondHeader, ...combinedHeaders]);
		}
	}, [getAllPages, getAllHeaders]);

	// todo: CREATE STATIC PAGE API BIND
	const [createStaticPage, { isLoading: createStaticPageLoading }] =
		useCreateBlogMutation();

	// todo: UPDATE STATIC PAGE API BIND
	const [updateStaticPage, { isLoading: updateStaticPageLoading }] =
		useUpdateBlogMutation();

	const resetFormFields = () => {
		setStaticPageTitle("");
		setStaticPageContent("");
		setEditStaticPage(null);
		setArabicTitle("");
		setArabicContent("");
	};

	const createBlogHandle = async () => {
		if (staticPageTitle === "" || staticPageTitle.trim() === "") {
			return toast.warn("Please enter static page title in English");
		}

		if (arabicTitle === "" || arabicTitle.trim() === "") {
			return toast.warn("Please enter static page title in Arabic");
		}

		const cleanedValue1 = staticPageContent.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
		const cleanedValue2 = arabicContent.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags

		if (!cleanedValue1 || cleanedValue1.trim() === "") {
			toast.warning(
				"Please enter some text to save the static page content in English"
			);
			return;
		}

		if (!cleanedValue2 || cleanedValue2.trim() === "") {
			toast.warning(
				"Please enter some text to save the static page content in Arabic"
			);
			return;
		}

		const payload = {
			en_title: staticPageTitle,
			en_content: staticPageContent,
			ar_title: arabicTitle,
			ar_content: arabicContent,
		};

		if (editStaticPage) {
			try {
				const update = await updateStaticPage({
					id: editStaticPage,
					body: payload,
					staticPage: true,
				});

				if (update?.error) {
					toast.error("Something went wrong");
					return;
				}

				const oldHeaderIndex = headers.findIndex(
					(header) => header.uuid === editStaticPage
				);

				const newHeader = {
					name: update?.data?.en_title,
					route: update?.data?.slug,
					uuid: headers[oldHeaderIndex]?.uuid,
					active: headers[oldHeaderIndex]?.active,
				};

				const updatedHeaders = [
					...headers.slice(0, oldHeaderIndex),
					newHeader,
					...headers.slice(oldHeaderIndex + 1),
				];
				setHeaders(updatedHeaders);

				const formData = new FormData();
				formData.append("headers", JSON.stringify(updatedHeaders));

				try {
					handleLoader(true);
					await axios.put(`${baseUrl}/api/set/header`, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: `Token ${token}`,
						},
					});
					handleLoader(false);
				} catch (err) {
					console.log("Error updating headers:", err);
					toast.error("Failed to update headers");
					handleLoader(false);
					return;
				}

				toast.success("Static Page updated successfully!");
				resetFormFields();
				refetchHeaders();
			} catch (error) {
				console.error("Update Static Page Error:", error);
				toast.error(error.response.data.message);
			}

			return;
		}

		try {
			const create = await createStaticPage({
				body: payload,
				staticPage: true,
			});

			if (create?.error) {
				toast.error("Something went wrong");
				return;
			}

			const newHeader = {
				name: create?.data?.en_title,
				route: create?.data?.slug,
				active: false,
				uuid: create?.data?.uuid,
			};

			const updatedHeaders = [...headers, newHeader];
			setHeaders(updatedHeaders);

			const formData = new FormData();
			formData.append("headers", JSON.stringify(updatedHeaders));

			try {
				handleLoader(true);
				await axios.put(`${baseUrl}/api/set/header`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Token ${token}`,
					},
				});
				handleLoader(false);
			} catch (err) {
				console.log("Error updating headers:", err);
				toast.error("Failed to update headers");
				handleLoader(false);

				return;
			}

			toast.success("Static Page created successfully!");
			resetFormFields();
			refetchHeaders();
		} catch (error) {
			console.error("Create Static Page Error:", error);
			toast.error(error.response.data.message);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	// todo: DELETE STATIC PAGE API BIND
	const [deleteBlog, { isLoading: deleteLoading }] = useDeleteBlogMutation();

	const deleteBlogHandle = async (id) => {
		try {
			const deleteCharge = await deleteBlog({ id, staticPage: true });

			if (!deleteCharge?.error) {
				// Find the header to be deleted
				const updatedHeaders = headers.filter((header) => header.uuid !== id);

				// Update headers state with the updated headers array
				setHeaders(updatedHeaders);

				// Prepare form data with the updated headers
				const formData = new FormData();
				formData.append("headers", JSON.stringify(updatedHeaders));

				toast.success("Static Page deleted successfully!");
				setStaticPageTitle("");
				setStaticPageContent("");
				setEditStaticPage(null);
				setArabicTitle("");
				setArabicContent("");
				refetchHeaders();

				try {
					setSecondLoader(true);
					await axios.put(`${baseUrl}/api/set/header`, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: `Token ${token}`,
						},
					});
					setSecondLoader(false);
				} catch (err) {
					console.log("Error", err);
					toast.error("Failed to update headers");
					setSecondLoader(false);
				}
			} else {
				toast.error("Something went wrong", "error");
			}
		} catch (error) {
			console.error("Static Page Deleted Type Error:", error);
			toast.error(error.response.data.message);
		}
	};

	return (
		<>
			<Wrapper maxWidth={false}>
				<Heading data={headerData} />

				<Box
					sx={{
						margin: "20px 0",
					}}
				>
					<SectionWrapper mt={3}>
						<Grid
							container
							spacing={3}
							sx={{
								display: "flex",
							}}
						>
							<Grid item md={6}>
								<Box
									sx={{
										marginBottom: "20px",
									}}
								>
									<SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
										Static Page Title
									</SubHeading>
									<PrimaryInput
										value={staticPageTitle}
										onChange={(e) => setStaticPageTitle(e.target.value)}
										label=""
										placeholder="Enter Static Page Title"
										borderRadius="0"
									/>
								</Box>
								<Box>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
											Static Page Content
										</SubHeading>

										{loading && (
											<Box sx={{ marginBottom: "5px" }}>
												<Box
													sx={{
														fontSize: "12px",
														marginBottom: "5px",
													}}
												>
													Image Uploading...
												</Box>
												<BarLoader color="#d49e6e" size={30} />
											</Box>
										)}
									</Box>
									<TextEditor
										value={staticPageContent}
										onChange={(value) => setStaticPageContent(value)}
										placeholder="Write something awesome..."
										setLoading={setLoading}
									/>
								</Box>
							</Grid>

							<Grid item md={6}>
								<Box
									sx={{
										marginBottom: "20px",
									}}
								>
									<SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
										Static Page Title (Arabic)
									</SubHeading>
									<PrimaryInput
										value={arabicTitle}
										onChange={(e) => setArabicTitle(e.target.value)}
										label=""
										placeholder="Enter Static Page Title (Arabic)"
										borderRadius="0"
									/>
								</Box>
								<Box>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
											Static Page Content (Arabic)
										</SubHeading>

										{loadingTwo && (
											<Box sx={{ marginBottom: "5px" }}>
												<Box
													sx={{
														fontSize: "12px",
														marginBottom: "5px",
													}}
												>
													Image Uploading...
												</Box>
												<BarLoader color="#d49e6e" size={30} />
											</Box>
										)}
									</Box>
									<TextEditor
										value={arabicContent}
										onChange={(value) => setArabicContent(value)}
										placeholder="Write something awesome... (Arabic)"
										setLoading={setLoadingTwo}
									/>
								</Box>
							</Grid>
							<Grid
								item
								md={12}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "end",
								}}
							>
								<SaveBTN
									onClick={createBlogHandle}
									disabled={secondLoader}
									variant="contained"
									sx={{
										"&:disabled": {
											color: "#fff",
										},
									}}
								>
									{contextLoading ||
										createStaticPageLoading ||
										updateStaticPageLoading ? (
										<>
											<MoonLoader color="#fff" size={20} />
										</>
									) : editStaticPage ? (
										"Update & Publish"
									) : (
										"Create"
									)}
								</SaveBTN>
							</Grid>
						</Grid>
					</SectionWrapper>
				</Box>

				{/* Table */}
				<Box>
					{getAllPagesLoading && <Loader />}
					<Box
						sx={{
							margin: "10px 0",
							boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
						}}
					>
						<MUITable tableHead={tableHead}>
							{isSuccess && getAllPages?.results?.length > 0 ? (
								getAllPages?.results?.map((row, index) => {
									return (
										<StyledTableRow
											key={row?.uuid}
											sx={{
												borderBottomLeftRadius: "0",
												borderBottom: "100px",
											}}
										>
											<StyledTableCell>{index + 1}</StyledTableCell>

											<StyledTableCell>{row?.en_title}</StyledTableCell>
											<StyledTableCell>
												{truncateString(row?.en_content, 30)}
											</StyledTableCell>

											<StyledTableCell>{row?.ar_title}</StyledTableCell>
											<StyledTableCell>
												{truncateString(row?.ar_content, 30)}
											</StyledTableCell>

											<StyledTableCell>
												<Box
													sx={{
														display: "flex",
														gap: "15px",
													}}
												>
													<Tooltip title="Edit" placement="top">
														<EditIcon
															sx={{ cursor: "pointer", color: "#07bc0c" }}
															onClick={() => {
																setEditStaticPage(row?.uuid);
																setStaticPageTitle(row?.en_title);
																setStaticPageContent(row?.en_content);
																setArabicTitle(row?.ar_title);
																setArabicContent(row?.ar_content);
															}}
														/>
													</Tooltip>
													{deleteLoading && row?.uuid === selectedStaticPage ? (
														<MoonLoader color="#000" size={16} />
													) : (
														<Tooltip title="Delete" placement="top">
															<DeleteIcon
																sx={{ cursor: "pointer", color: "red" }}
																onClick={() => {
																	setSelectedStaticPage(row?.uuid);
																	deleteBlogHandle(row?.uuid);
																}}
															/>
														</Tooltip>
													)}
												</Box>
											</StyledTableCell>
										</StyledTableRow>
									);
								})
							) : (
								<StyledTableRow>
									<StyledTableCell
										sx={{ height: "100px" }}
										colSpan={tableHead?.length}
										align="center"
									>
										<Box
											sx={{
												fontSize: "18px",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												gap: 1,
											}}
										>
											{getAllPages?.results?.length === 0
												? "No records found"
												: ""}
										</Box>
									</StyledTableCell>
								</StyledTableRow>
							)}
						</MUITable>
						<Box
							sx={{
								background: "#fff",
								display: "flex",
								justifyContent: "end",
								// margin: "1px 0 0",
								padding: "15px 10px",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									fontSize: "14px",
									fontWeight: 400,
									color: "#49464f",
									marginRight: "10px",
								}}
							>
								Total Records: {getAllPages?.total_count}
							</Box>
							<Pagination
								count={Math.ceil(getAllPages?.total_count / RowsPerPage)}
								shape="rounded"
								onChange={handleChangePage}
							/>
						</Box>
					</Box>
				</Box>
			</Wrapper>
		</>
	);
};

const Wrapper = styled(Container)(() => ({
	width: "100%",
	padding: "1rem",
	height: "calc(100vh - 60px)",
	overflow: "auto",
}));

const SectionWrapper = styled(Box)(() => ({
	width: "100%",
	background: "#fff",
	padding: "20px",
	borderRadius: "8px",
	fontSize: "22px",
	fontWeight: "600",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
	width: "160px",
	// padding: "10px 24px",
	height: "40px",
	marginTop: "15px",
	fontSize: "16px",
	background: "#d49e6e",
	color: "#fff",
	borderRadius: "5px",
	textTransform: "capitalize",
	"&:hover": {
		backgroundColor: "#d49e6e",
	},
}));

export default StaticPages;
