import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import 
import { Box, Typography } from '@mui/material'

const Heading = ({ data }) => {
    return (
        <Box>
            <Text>{data.heading}</Text>
            <SubText mt={1}>{data.subheading}</SubText>
        </Box>
    )
}

// Styled Components

const Text = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "700",
}));

const SubText = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "400",
    color: "#9FA1A6",
}));

export default Heading