import React from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from '@mui/material';

// Components Import
import Heading from '../components/Heading';

const headerData = {
    heading: "Track your sales",
    subheading: "",
}

function Dashboard() {
  return (
    <>
            <Wrapper maxWidth={false}>
                <Heading data={headerData} />           
            </Wrapper>
        </>
  )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    width: "100%",
    padding: '1rem',
    height: "calc(100vh - 60px)",
    overflow: "auto"
}))

export default Dashboard