import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

// MUI Components Import
import {
  Box,
  Checkbox,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import { baseUrl } from "../../../../constants/api";
import { EXPIRED_PAGINATION_LIMIT } from "../../../../constants/constant";
import { useBulkProductsDeleteMutation } from "../../../../redux/api/utilApiSlice";

function ExpiringProductTable() {
  const token = window.localStorage.getItem("user-token");

  const [expiredProducts, setExpiredProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);

  const fetchExpiredProducts = () => {
    axios
      .get(`${baseUrl}stocks`, {
        params: {
          limit: EXPIRED_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setExpiredProducts(response.data?.results);
        setCount(response.data?.total_count);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}product/stock/edit/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Batch deleted successfully!");
        fetchExpiredProducts();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchExpiredProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableHead = [
    // "#",
    "Product Name",
    "Batch No.",
    "Expiry Date",
    "Quantity",
    "Action",
  ];

  const isSelected = (id) => selected?.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected?.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // todo: BULK DELETE TYPE API BIND
  const [bulkDeleteProducts, { isLoading }] = useBulkProductsDeleteMutation();

  const bulkDelete = async (id) => {
    const payload = {
      ids: id,
    };

    try {
      const create = await bulkDeleteProducts(payload);

      if (!create?.error) {
        toast.success("Multiple products deleted successfully");
        setSelected([]);
        fetchExpiredProducts();
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Multiple Products Delete Error:", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Card item md={12.0}>
        <CardHeading>Expired Products</CardHeading>
        <Box>
          <Box
            sx={{
              margin: "10px 0",
            }}
          >
            <MUITable
              tableHead={tableHead}
              handleChangePage={handleChangePage}
              selected={selected}
              setSelected={setSelected}
              bulkDelete={bulkDelete}
              rows={expiredProducts}
              loading={isLoading}
            >
              {expiredProducts?.length > 0 ? (
                expiredProducts?.map((item, index) => {
                  const isItemSelected = isSelected(item?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow
                      onClick={(event) => handleClick(event, item?.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={item?.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <StyledTableCell>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          sx={{
                            padding: 0,
                            "& .MuiButtonBase-root": {
                              padding: 0,
                            },
                          }}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        {truncate(item?.product?.commons?.en?.productName, 40)},
                        {truncate(item?.product?.commons?.ar?.productName, 40)}
                      </StyledTableCell>
                      <StyledTableCell>{item?.batchNumber}</StyledTableCell>
                      <StyledTableCell>{item?.expiryDate}</StyledTableCell>
                      <StyledTableCell>{item?.productQuantity}</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="delete" placement="top">
                          <DeleteIcon
                            sx={{
                              fontSize: "20px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            // onClick={() => handleDelete(item.uuid)}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleDelete(item.uuid);
                            }}
                          />
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {expiredProducts?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "20px 0 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#49464f",
                  }}
                >
                  Total Records: {expiredProducts?.length}
                </Box>
                <Pagination
                  count={Math.ceil(count / EXPIRED_PAGINATION_LIMIT)}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
}

// Styled Components
const Card = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  padding: "15px 25px",
  borderRadius: "14px",
  border: "1px solid #ddd",
}));

const CardHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
}));

export default ExpiringProductTable;
