import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";

// Loader Import
import { MoonLoader } from "react-spinners";

// MUI Components Import
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components Import

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";

// Formik & Yup Imports
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { baseUrl } from "../../../../constants/api";
import Heading from "../../components/Heading";

const validationSchema = Yup.object({
  engName: Yup.string().required("English Name is required"),
  arabicName: Yup.string().required("Arabic Name is required"),
});

const headerData = {
  heading: "Configure Units",
  subheading: "",
};

function Units() {
  const token = window.localStorage.getItem("user-token");

  const { loading, handleLoader } = useLoaderContext();

  const [saving, setSaving] = useState(false);
  const [unitsList, setUnitsList] = useState([]);
  const [editingUnit, setEditingUnit] = useState(null);

  const formik = useFormik({
    enableReinitialze: true,
    initialValues: {
      engName: editingUnit != null ? editingUnit.name : "",
      arabicName: editingUnit != null ? editingUnit.arb_name : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (editingUnit) {
        handleEdit(editingUnit.uuid, values);
      } else {
        handleCreate(values);
      }
    },
  });

  const editUnit = (unit) => {
    console.log("Editing unit:", unit);
    formik.setFieldValue("engName", unit.name);
    formik.setFieldValue("arabicName", unit.arb_name);
    setEditingUnit(unit);
  };

  const handleCreate = (formValues) => {
    setSaving(true);
    axios
      .post(
        `${baseUrl}uom/create`,
        {
          name: formValues.engName,
          arb_name: formValues.arabicName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Unit created successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchEntities();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleEdit = (id, formValues) => {
    setSaving(true);
    axios
      .put(
        `${baseUrl}uom/update/${id}`,
        {
          name: formValues.engName,
          arb_name: formValues.arabicName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        toast.success("Unit edited successfully!");
        setTimeout(() => {
          setSaving(false);
        }, 2000);
        formik.resetForm();
        fetchEntities();
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}uom/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast.success("Unit deleted successfully!");
        fetchEntities();
        setUnitsList(response.data.brands);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  const fetchEntities = () => {
    handleLoader(true);
    axios
      .get(`${baseUrl}entities`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setUnitsList(response.data.uoms);
        handleLoader(false);
      })
      .catch((error) => {
        console.log("Error", error);
        handleLoader(false);
      });
  };

  useEffect(() => {
    fetchEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper maxWidth={false}>
      <Heading data={headerData} />
      <ControlWrapper mt={3}>
        <ControlHeading>Create a Unit</ControlHeading>
        <Grid
          container
          mt={1}
          spacing={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item md={4}>
            <Label>Unit Name (English)</Label>
            <Input
              name="engName"
              {...formik.getFieldProps("engName")}
              error={formik.touched.engName && Boolean(formik.errors.engName)}
              helperText={formik.touched.engName && formik.errors.engName}
            />
          </Grid>
          <Grid item md={4}>
            <Label>Unit Name (Arabic)</Label>
            <Input
              name="arabicName"
              {...formik.getFieldProps("arabicName")}
              error={
                formik.touched.arabicName && Boolean(formik.errors.arabicName)
              }
              helperText={formik.touched.arabicName && formik.errors.arabicName}
            />
          </Grid>
          <Grid item md={4} display={"flex"} justifyContent={"end"}>
            <SaveBTN onClick={() => formik.handleSubmit()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : editingUnit ? (
                "Update"
              ) : (
                "Create"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </ControlWrapper>
      <TableWrapper component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: "#DDDDDD" }}>
            <TableRow>
              <TableHeadings>#</TableHeadings>
              <TableHeadings>Name</TableHeadings>
              <TableHeadings>Action</TableHeadings>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableContent sx={{ width: "100%" }}>
                  <MoonLoader color="#000" size={20} />
                </TableContent>
              </TableRow>
            ) : unitsList.length === 0 ? (
              <TableRow align="center">
                <TableContent colSpan={4} align="center">
                  You do not have any units yet!
                </TableContent>
              </TableRow>
            ) : (
              unitsList.map((unit, index) => (
                <TableRow key={index}>
                  <TableContent sx={{ width: "1%" }}>{index + 1}</TableContent>
                  <TableContent sx={{ width: "90%" }}>
                    {unit.name} , {unit.arb_name}
                  </TableContent>
                  <TableContent
                    sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                  >
                    <Tooltip title="Edit" placement="top">
                      <EditIcon
                        sx={{ fontSize: "20px", color: "#000" }}
                        onClick={() => editUnit(unit)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete" placement="top">
                      <DeleteIcon
                        sx={{ fontSize: "20px", color: "red" }}
                        onClick={() => handleDelete(unit.uuid)}
                      />
                    </Tooltip>
                  </TableContent>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const ControlWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const ControlHeading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Units;
