import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";

// MUI Components import
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";


// Icons Import
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";

// React Toastify Imports
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../constants/api";
import { CUSTOMER_PAGINATION_LIMIT } from "../../../../constants/constant";
import { useGetCustomerQuery } from "../../../../redux/api/customerAPISlice";
import Loader from "../../../Loaders/Loader";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import EditIcon from '@mui/icons-material/Edit';

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import CustomerEditModal from "../../InventoryDash/components/Modals/CustomerEditModal";
import Heading from "../../components/Heading";

const headerData = {
  heading: "Customers List",
  subheading: "Keep track of your customers",
};

function CustomerList() {
  const token = window.localStorage.getItem("user-token");

  const { handleLoader } = useLoaderContext();
  const [customers, setCustomers] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [orderRange, setOrderRange] = useState("");
  const [spendingFilter, setSpendingFilter] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const { data, isLoading, isSuccess } = useGetCustomerQuery({
    limit: CUSTOMER_PAGINATION_LIMIT,
    offset: page,
  });

  const fetchCustomerList = () => {
    setSearchTerm("");
    setOrderRange("");
    setSpendingFilter("");
    handleLoader(true);
    axios
      .get(`${baseUrl}customers`, {
        params: {
          limit: CUSTOMER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setCustomers(response.data.customers);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        console.log(err);
        handleLoader(false);
      });
  };

  const searchCustomer = () => {
    handleLoader(true);
    axios
      .post(
        `${baseUrl}customers`,
        {
          search_term: searchTerm === "" ? null : searchTerm,
        },

        {
          params: {
            limit: CUSTOMER_PAGINATION_LIMIT,
            offset: page,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        setCustomers(response.data.customers);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const applyFilters = () => {
    handleLoader(true);
    let payload = {};
    const start_OrderRange = orderRange.split("-")[0];
    const end_OrderRange = orderRange.split("-")[1];
    const start_SpendingFilter = spendingFilter.split("-")[0];
    const end_SpendingFilter = spendingFilter.split("-")[1];
    if (orderRange !== "") {
      payload.no_order_start = start_OrderRange;
      payload.no_order_end = end_OrderRange;
    }
    if (spendingFilter !== "") {
      payload.spent_start = start_SpendingFilter;
      payload.spent_end = end_SpendingFilter;
    }
    axios
      .post(`${baseUrl}customers`, payload, {
        params: {
          limit: CUSTOMER_PAGINATION_LIMIT,
          offset: page,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setCustomers(response.data.customers);
        setCount(response.data?.total_count);
        handleLoader(false);
      })
      .catch((err) => {
        handleLoader(false);
      });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  async function downloadCSV() {
    try {
      const response = await axios.get(`${baseUrl}customers/download`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Token ${token}`,
        },
        responseType: "blob", // Specify response type as 'blob' to handle binary data
      });
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "products.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("xlsx file downloaded successfully.");
      } else {
        toast.error("Failed to download xlsx file.");
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  }

  useEffect(() => {
    // fetchCustomerList();
    if (data) {
      setCount(data?.total_count);
      setCustomers(data?.customers);
    }
  }, [data, page]);

  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);

    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditing(false);
    setIsModalOpen(false);
  };

  const tableHead = [
    "#",
    "Name",
    "Email",
    "Phone",
    "Total Orders",
    "Total Spendings",
    "Registered At",
    "Action",
  ];

  return (
    <>
      <CustomerEditModal
        open={isModalOpen}
        setOpen={handleModalClose}
        fullName={selectedCustomer?.fullname}
        custEmail={selectedCustomer?.email}
        custPhone={selectedCustomer?.phone_number}
        fetchCustomer={fetchCustomerList}
        setSelectedCustomer={setSelectedCustomer}
        id={selectedCustomer?.profId}
      />
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading data={headerData} />
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Button
              style={{ textTransform: "none" }}
              variant="contained"
              color="secondary"
              backgroundcolor={"green"}
              startIcon={<DownloadIcon />}
              onClick={() => downloadCSV()}
            >
              Download XLXS
            </Button>
          </Box>
        </Box>
        <SearchNav>
          <Box
            sx={{
              width: "30vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "#bdbdbd" }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by name, email, phone"
                inputProps={{ "aria-label": "search" }}
                sx={{ width: "90%", left: "30px" }}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Search>
            <Button onClick={() => searchCustomer()}>Search</Button>
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Dropdown size="small" fullWidth>
              <InputLabel id="order-range-label">Order range</InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="order-range-label"
                id="order-range-select"
                value={orderRange}
                label="Order Range"
                onChange={(e) => setOrderRange(e.target.value)}
              >
                <MenuItem value="0-10">Less than 10 Orders</MenuItem>
                <MenuItem value="11-50">11 - 50 Orders</MenuItem>
                <MenuItem value="51-100">51 - 100 Orders</MenuItem>
                <MenuItem value="101-500">More than 100 Orders</MenuItem>
              </Select>
            </Dropdown>
            <Dropdown size="small" fullWidth>
              <InputLabel id="spending-filter-label">
                Spending filter
              </InputLabel>
              <Select
                sx={{ borderRadius: "6px", border: "none" }}
                labelId="spending-filter-label"
                id="spending-filter-select"
                value={spendingFilter}
                label="Spending filter"
                onChange={(e) => setSpendingFilter(e.target.value)}
              >
                <MenuItem value="0-50000">Less than 50,000 SAR</MenuItem>
                <MenuItem value="50001-200000">50,001 - 200,000 SAR</MenuItem>
                <MenuItem value="200000-500000">More than 200,000 SAR</MenuItem>
              </Select>
            </Dropdown>
            <Button onClick={() => applyFilters()}>Apply</Button>
            <Button onClick={() => fetchCustomerList()}>Reset</Button>
          </Box>
        </SearchNav>

        <Box>
          {isLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
              {isSuccess && customers?.length > 0 ? (
                customers?.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{row?.fullname}</StyledTableCell>
                      <StyledTableCell>{row?.email}</StyledTableCell>
                      <StyledTableCell>{row?.phone_number}</StyledTableCell>
                      <StyledTableCell>{row?.ordered_products}</StyledTableCell>
                      <StyledTableCell>
                        {parseFloat(row?.total_spent || 0).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(row?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton size="small" >
                          <EditIcon sx={{ fontSize: '22px' }}
                            onClick={() => {
                              handleEditClick()
                              setSelectedCustomer(row)
                            }} />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {data?.data?.length === 0 ? "No records found" : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                margin: "1px 0 0",
                padding: "15px 10px",
              }}
            >
              <Pagination
                count={Math.ceil(count / CUSTOMER_PAGINATION_LIMIT)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
}

// Styled Components

const Wrapper = styled(Container)(() => ({
  padding: "1rem",
  width: "100%",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const Dropdown = styled(FormControl)(() => ({
  width: "100%",
  border: "1px solid #EFEFEF",
  marginTop: "5px",
  backgroundColor: "#fff",
  borderRadius: "6px",
}));

const Search = styled("div")(({ theme }) => ({
  height: "40px",
  position: "relative",
  borderRadius: "10px",
  backgroundColor: "#F7F9FB",
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchNav = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  padding: "20px",
  backgroundColor: "#fff",
  border: "1px solid #E8E8E8",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "10px 0",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  left: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
    },
  },
}));

export default CustomerList;
