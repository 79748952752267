import React, { useEffect, useState } from 'react'
import axios from 'axios';

// MUI Components Import
import { Box, Button, Grid, TextField, Typography, styled } from '@mui/material'

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../../../../constants/api';

function Contact() {

    const token = window.localStorage.getItem("user-token");

    const [saving, setSaving] = useState(false);
    const [contactId, setContactId] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState(false)
    const [engAddress, setEngAddress] = useState('')
    const [arbAddress, setArbAddress] = useState('')

    const fetchContactData = () => {
        axios.get(`${baseUrl}footer/contact`)
            .then((response) => {
                if (response.data.uuid) {
                    setContactId(response.data.uuid);
                } else {
                    setContactId("");
                }
                setEmail(response.data.email)
                setPhone(response.data.phone)
                setEngAddress(response.data.eng_address)
                setArbAddress(response.data.arb_address)
            })
            .catch(() => {
            });
    };

    const handleContact = () => {
        setSaving(true);

        let response;

        if (contactId === '') {
            response = axios.post(`${baseUrl}footer/contact/create`,
                {
                    email,
                    phone,
                    eng_address: engAddress,
                    arb_address: arbAddress,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                }
            )
        } else {
            response = axios.put(`${baseUrl}footer/contact/${contactId}`,
                {
                    email,
                    phone,
                    eng_address: engAddress,
                    arb_address: arbAddress,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                }
            )
        }
        response
            .then(() => {
                toast.success("Contact Details added successfully!");
                setSaving(false);
            })
            .catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                }
                setSaving(false);
            });
    }

    useEffect(() => {
        fetchContactData()
    }, [])

    return (
        <>
            <SectionWrapper mt={3}>
                <Heading>Contact Information</Heading>
                <Grid container mt={1} spacing={3} display={"flex"} alignItems={"center"} >
                    <Grid item md={5}>
                        <Label>Email Address</Label>
                        <Input mt={3} type="text" color='success'
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={email} onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <Label>Phone Number</Label>
                        <Input mt={3} type="number" color='success'
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={phone} onChange={(e) => setPhone(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <Label>Address (English)</Label>
                        <Input mt={3} type="text" color='success'
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={engAddress} onChange={(e) => setEngAddress(e.target.value)}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <Label>Address (Arabic)</Label>
                        <Input mt={3} type="text" color='success'
                            InputProps={{ sx: { borderRadius: 3 } }}
                            value={arbAddress} onChange={(e) => setArbAddress(e.target.value)}
                        />
                    </Grid>

                    <Grid item md={12} sx={{ display: "flex", justifyContent: "end" }}>
                        <SaveBTN onClick={() => handleContact()}>
                            {saving ? (
                                <>
                                    <MoonLoader color="#fff" size={20} />
                                </>
                            ) : (
                                "Save"
                            )}
                        </SaveBTN>
                    </Grid>
                </Grid>
            </SectionWrapper>
        </>
    )
}

const SectionWrapper = styled(Box)(() => ({
    width: "100%",
    background: "#fff",
    padding: "10px 15px",
    borderRadius: "12px",
    fontSize: "22px",
    fontWeight: "600"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: "600",
    color: "#424242"
}))

const Input = styled(TextField)(({
    width: "100%",
    background: "#fff",
    borderRadius: "5px",
}))

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

const SaveBTN = styled(Button)(({ theme }) => ({
    width: '120px',
    height: '40px',
    marginTop: "15px",
    fontSize: "16px",
    background: `${theme.palette.primary.main}`,
    color: "#fff",
    borderRadius: '5px',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`
    }
}))

export default Contact