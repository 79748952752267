import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

// MUI Components Import
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";

// Icons Import
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icons Import
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import { baseUrl } from "../../../../../constants/api";

function Partners() {
  const token = window.localStorage.getItem("user-token");

  const imgUpload = useRef();
  const selectFiles = () => {
    imgUpload.current.click();
  };

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [partners, setPartners] = useState([]);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [icon, setIcon] = useState(null);
  const [partnerId, setPartnerId] = useState("");
  const [previewIcon, setPreviewIcon] = useState([]);
  const [updating, setUpdating] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIcon(file);
      setPreviewIcon(URL.createObjectURL(file));
    }
  };

  const fetchPartnersData = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}footer/partner`)
      .then((response) => {
        setPartners(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handlePartners = () => {
    const requiredFields = [
      { value: name, message: "Name is required." },
      { value: link, message: "Link is required." },
      { value: icon, message: "Icon is required." },
    ];
  
    for (const field of requiredFields) {
      if (!field.value) {
        toast.error(field.message);
        return;
      }
    }
  
    setSaving(true);
  
    const formData = new FormData();
    formData.append("name", name);
    formData.append("link", link);
    if (typeof icon !== "string") {
      formData.append("icon", icon);
    }
  
    const requestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    };
  
    const response = !updating
      ? axios.post(`${baseUrl}footer/partner/create`, formData, requestConfig)
      : axios.put(`${baseUrl}footer/partner/${partnerId}`, formData, requestConfig);
  
    response
      .then(() => {
        toast.success("Partner added successfully!");
        setSaving(false);
        setName("");
        setLink("");
        setIcon(null);
        setPreviewIcon(null);
        fetchPartnersData();
        setUpdating(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.link[0] || "An error occurred");
        } else {
          toast.error("An error occurred");
        }
        setSaving(false);
      });
  };
  

  // const handlePartners = () => {
  //   setSaving(true);

  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("link", link);
  //   if (typeof icon !== "string") {
  //     formData.append("icon", icon);
  //   }

  //   let response;
  //   if (!updating) {
  //     response = axios.post(`${baseUrl}footer/partner/create`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Token ${token}`,
  //       },
  //     });
  //   } else {
  //     response = axios.put(
  //       `${baseUrl}footer/partner/${partnerId}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Token ${token}`,
  //         },
  //       }
  //     );
  //   }
  //   response
  //     .then(() => {
  //       toast.success("Partner added successfully!");
  //       setSaving(false);
  //       setName("");
  //       setLink("");
  //       setIcon(null);
  //       setPreviewIcon(null);
  //       fetchPartnersData();
  //       setUpdating(false);
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         toast.error(err.response.data.message);
  //       }
  //       setSaving(false);
  //     });
  // };

  const editPartner = (partner) => {
    setUpdating(true);
    setName(partner.name);
    setLink(partner.link);
    setIcon(partner.icon);
    setPreviewIcon(partner.icon);
    setPartnerId(partner.uuid);
  };

  const handleDelete = (id) => {
    axios
      .delete(`${baseUrl}footer/partner/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Partner deleted successfully!");
        fetchPartnersData();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    fetchPartnersData();
  }, []);

  return (
    <>
      <PartnersWrapper mt={3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading>Partners</Heading>
        </Box>
        <Grid
          container
          mt={1}
          spacing={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item md={3}>
            <Label>Partner Name</Label>
            <Input
              mt={3}
              type="text"
              color="success"
              InputProps={{ sx: { borderRadius: 3 } }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item md={3}>
            <Label>Partner URL</Label>
            <Input
              mt={3}
              type="text"
              color="success"
              InputProps={{ sx: { borderRadius: 3 } }}
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Grid>
          <Grid item md={6} display={"flex"} alignItems={"center"}>
            <Uploader item md={3} onClick={() => selectFiles()}>
              <input
                type="file"
                accept="image/*"
                ref={imgUpload}
                style={{ display: "none" }}
                onChange={(e) => handleImageUpload(e)}
                id="imageInput"
              />
              <AddPhotoIcon sx={{ fontSize: "25px" }} />
              <Typography
                sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
              >
                Add Icon
              </Typography>
            </Uploader>
            <Grid item md={9}>
              {icon ? (
                <ImgDisplay
                  sx={{
                    background: `url(${previewIcon}) center/cover no-repeat`,
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={11} sx={{ display: "flex", justifyContent: "end" }}>
          <SaveBTN onClick={() => handlePartners()}>
            {saving ? (
              <>
                <MoonLoader color="#fff" size={20} />
              </>
            ) : updating ? (
              "update"
            ) : (
              "save"
            )}
          </SaveBTN>
        </Grid>
        <TableWrapper component={Paper}>
          <Table>
            <TableHead style={{ backgroundColor: "#DDDDDD" }}>
              <TableRow>
                <TableHeadings>#</TableHeadings>
                <TableHeadings>Icon</TableHeadings>
                <TableHeadings>Name</TableHeadings>
                <TableHeadings>URL</TableHeadings>
                <TableHeadings>Action</TableHeadings>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableContent sx={{ width: "100%" }}>
                    <MoonLoader color="#000" size={20} />
                  </TableContent>
                </TableRow>
              ) : partners.length === 0 ? (
                <TableRow align="center">
                  <TableContent colSpan={5} align="center">
                    You have not added any partner yet!
                  </TableContent>
                </TableRow>
              ) : (
                partners?.map((partner, index) => (
                  <TableRow key={index}>
                    <TableContent sx={{ width: "1%" }}>
                      {index + 1}
                    </TableContent>
                    <TableContent sx={{ width: "5%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <img
                          src={partner?.icon}
                          alt="category_image"
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "5px",
                            marginRight: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </TableContent>
                    <TableContent sx={{ width: "30%" }}>
                      {partner.name}
                    </TableContent>
                    <TableContent sx={{ width: "30%" }}>
                      {partner.link}
                    </TableContent>
                    <TableContent
                      sx={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <EditIcon
                          sx={{ fontSize: "20px", color: "#000" }}
                          onClick={() => editPartner(partner)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon
                          sx={{ fontSize: "20px", color: "red" }}
                          onClick={() => handleDelete(partner.uuid)}
                        />
                      </Tooltip>
                    </TableContent>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
      </PartnersWrapper>
    </>
  );
}

const PartnersWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const Uploader = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90px",
  cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
  width: "100px",
  height: "90px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: "10px",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const TableWrapper = styled(TableContainer)(() => ({
  height: "auto",
  overflow: "auto",
  border: "none",
  boxShadow: "none",
  margin: "30px 0",
}));

const TableHeadings = styled(TableCell)(() => ({
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "16px",
  color: "black",
  background: "#F2F4F5",
}));

const TableContent = styled(TableCell)(() => ({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  color: "#71747D",
  border: "none",
}));

export default Partners;
