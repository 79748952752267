import React from 'react'
import { Button, Grid, Typography, styled } from '@mui/material'

function Testimonials({ state, handleContentChange, removeSection }) {

    const { bgColor } = state;

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
                                Testimonials
                            </Typography>
                            <Typography sx={{fontSize: "12px", fontWeight: "400"}}>
                                This section features a carousel for the testimonials given by the clients and users.
                            </Typography>
                        </Grid>
                        <Grid item md={6} display={"flex"} flexDirection={"column"} alignItems={"end"}>
                            <Label>Section Background Color</Label>
                            <input
                                type="color" 
                                value={bgColor} 
                                onChange={(e) => handleContentChange('Testimonials', 'bgColor', e.target.value)}
                                style={{ width: '30%' }}  
                            />
                            <Typography>{bgColor}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} display={"flex"} justifyContent={"end"}>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px", textTransform: "capitalize"}}
                        onClick={removeSection}
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500",
}));

export default Testimonials