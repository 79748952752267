import React, { useMemo } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageUploader from "quill-image-uploader";
import BlotFormatter from "quill-blot-formatter";
import Quill from "quill";
import CustomImage from "./CustomImage"; // Import your custom image format
import { baseUrl } from "../../constants/api";

// Register modules and attributors
if (typeof window !== "undefined") {
  if (!Quill.imports["modules/imageUploader"]) {
    Quill.register("modules/imageUploader", ImageUploader);
  }

  Quill.register(CustomImage);
  if (!Quill.imports["modules/blotFormatter"]) {
    Quill.register("modules/blotFormatter", BlotFormatter);
  }
  Quill.register(Quill.import("attributors/style/direction"), true);
  Quill.register(Quill.import("attributors/style/align"), true);
}

const TextEditor = ({ value, onChange, placeholder, setLoading }) => {
  const token = localStorage.getItem("user-token");

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6] }],
        [{ size: ["small", "medium", "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ color: [] }],
        ["clean"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
      ],

      imageUploader: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file);
            setLoading(true);

            fetch(`${baseUrl}file/upload`, {
              method: "POST",
              body: formData,
              headers: {
                Authorization: `Token ${token}`,
              },
            })
              .then((response) => response.json())
              .then((result) => {
                resolve(result.file_url);
                setLoading(false);
              })
              .catch((error) => {
                console.error("Error:", error);
                reject("Upload failed");
                setLoading(false);
              });
          });
        },
      },
      blotFormatter: {},
    }),
    [token, setLoading]
  );

  const formats = useMemo(
    () => [
      "header",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "link",
      "indent",
      "image",
      "color",
      "align",
      "style", // Include style to handle custom image styles
      "height", // Include height to handle custom image height
      "width", // Include width to handle custom image width
      "alt", // Include alt to handle custom image alt
    ],
    []
  );

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      modules={modules}
      formats={formats}
      style={{ height: "100%", overflow: "hidden" }}
    />
  );
};

export default TextEditor;
