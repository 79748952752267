import React from "react";
import { Grid, styled, Select, MenuItem, FormControl, InputLabel, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    position: "relative",
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#9ccbce" : "#fff",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: "12px",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#f5f5f5" : "#f6f6f6",
    padding: grid,
    width: "100%"
});

const SectionManager = ({ sections, setSections, availableSections, addSection, removeSection, handleContentChange }) => {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result.map((item, index) => ({
            ...item,
            index: index
        }));
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        const reorderedSections = reorder(sections, source.index, destination.index);
        setSections(reorderedSections);
    };

    const handleAddSection = (event) => {
        const sectionId = event.target.value;
        const sectionToAdd = availableSections.find(section => section.id === sectionId);
        addSection(sectionToAdd);
    };

    return (
        <BoxWrapper container my={2}>
            <Grid item md={12} mt={1} mb={3} sx={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: "30px"}}>
                <Typography sx={{fontSize: "12px"}}>
                    Drag and Drop sections to rearrange!
                </Typography>
                <FormControl size="small">
                    <InputLabel id="section">Select a Section</InputLabel>
                    <Select
                        label="Add Section"
                        variant="outlined"
                        style={{ width: '400px' }}
                        onChange={handleAddSection}
                    >   
                        { availableSections?.length > 0 ?
                            availableSections.map(section => (
                                <MenuItem key={section.id} value={section.id}>
                                    {section.content}
                                </MenuItem>
                            ))
                            :
                            <MenuItem disabled>No options to select</MenuItem>
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {sections?.map((item, index) => {
                                    const Component = item.component;
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Component 
                                                        state={item.state}
                                                        handleContentChange={handleContentChange}
                                                        removeSection={() => removeSection(item)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>

        </BoxWrapper>
    );
};



const BoxWrapper = styled(Grid)(() => ({
	width: "100%",
	background: "#fff",
	padding: "10px 15px",
	borderRadius: "12px",
	fontSize: "22px",
	fontWeight: "600",
}));

export default SectionManager;
