import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { baseUrl } from "../../../../constants/api";
import { useLoaderContext } from "../../../../contexts/LoaderContext";
import { REFUND_PAGINATION_LIMIT } from "../../../../constants/constant";

import axios from "axios";

// MUI Components import
import {
	Box,
	Button,
	Container,
	Pagination,
} from "@mui/material";

// Components Import
import Heading from "../../components/Heading";
import Loader from "../../../Loaders/Loader";
import MUITable, {
	StyledTableCell,
	StyledTableRow,
} from "../../../../components/MUITable";


// React Toastify Imports
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomChip from "../../../../components/CustomChip";
import { useGetRefundsQuery } from "../../../../redux/api/refundsApiSlice";

const headerData = {
	heading: "Refunds List",
	subheading: "Keep track of all the refunds requests in one place",
};

function RefundsList() {
	const token = window.localStorage.getItem("user-token");

	const { handleLoader } = useLoaderContext();
	const [refunds, setRefunds] = useState([]);
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);

	const { data, isLoading, isSuccess } = useGetRefundsQuery({
		limit: REFUND_PAGINATION_LIMIT,
		offset: page,
	});

	const fetchRefundsList = () => {
		handleLoader(true);
		axios
			.get(`${baseUrl}refunds/vendor`, {
				params: {
					limit: REFUND_PAGINATION_LIMIT,
					offset: page,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${token}`,
				},
			})
			.then((response) => {
				setRefunds(response.data?.results);
				setCount(response.data?.total_count);
				handleLoader(false);
			})
			.catch((err) => {
				handleLoader(false);
			});
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const getStatus = (rfId, status) => {
		axios
			.get(
				`${baseUrl}refund/update/${rfId}/${status}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Token ${token}`,
					},
				}
			)
			.then(() => {
				toast.success("Request has been approved!");
				fetchRefundsList();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		if (data) {
			setCount(data?.total_count);
			setRefunds(data?.results);
		}
	}, [data, page]);

	const tableHead = [
		"Refund ID",
		"Created at",
		"Status",
		"Order ID",
		"Refund Amount",
		"Remarks",
		"Action",
	];

	return (
		<>
			<Wrapper maxWidth={false}>
				<Heading data={headerData} />
				<Box>
					{isLoading && <Loader />}
					<Box
						sx={{
							margin: "10px 0",
							boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
						}}
					>
						<MUITable tableHead={tableHead} handleChangePage={handleChangePage}>
							{isSuccess && refunds?.length > 0 ? (
								refunds?.map((row) => {
									return (
										<StyledTableRow key={row?.order?.ordId}>
											<StyledTableCell>{row?.rfOId}</StyledTableCell>
											<StyledTableCell>
												{new Date(row?.created_at).toLocaleString()}
											</StyledTableCell>
											<StyledTableCell>
												<Box
												>
													{<CustomChip label={row?.refund_status_dsiplay} />}
												</Box>
											</StyledTableCell>
											<StyledTableCell>
												{row?.order?.ordId}
											</StyledTableCell>
											<StyledTableCell>
												{parseFloat(
													row?.refund_amount || 0
												).toLocaleString(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})}{" "}
												SR
											</StyledTableCell>
											<StyledTableCell>
												{row?.refund_reason}
											</StyledTableCell>
											<StyledTableCell>
												<Box
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
														gap: "2px",
														padding: "10px 0",
													}}
												>
													{row?.refund_status === 1 ? (
														<>
															<Button
																variant="contained"
																color="primary"
																sx={{
																	height: "40px",
																	width: "90px",
																}}
																onClick={() => getStatus(row?.rfOId, "accept")}
															>
																Accept
															</Button>
															<Button
																variant="contained"
																color="error"
																sx={{
																	height: "40px",
																	width: "90px",
																}}
																onClick={() => getStatus(row?.rfOId, "reject")}
															>
																Reject
															</Button>
														</>
													) : (
														<>
															<Button
																variant="contained"
																color="primary"
																sx={{
																	height: "40px",
																	width: "90px",
																}}
																onClick={() => getStatus(row?.rfOId, "accept")}
																disabled
															>
																Accept
															</Button>
															<Button
																variant="contained"
																color="error"
																sx={{
																	height: "40px",
																	width: "90px",
																}}
																onClick={() => getStatus(row?.rfOId, "reject")}
																disabled
															>
																Reject
															</Button>
														</>
													)}
												</Box>
											</StyledTableCell>
										</StyledTableRow>
									);
								})
							) : (
								<StyledTableRow>
									<StyledTableCell
										sx={{ height: "100px" }}
										colSpan={tableHead?.length}
										align="center"
									>
										<Box
											sx={{
												fontSize: "18px",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												gap: 1,
											}}
										>
											{refunds?.length === 0 ? "No records found" : ""}
										</Box>
									</StyledTableCell>
								</StyledTableRow>
							)}
						</MUITable>
						<Box
							sx={{
								background: "#fff",
								display: "flex",
								justifyContent: "end",
								margin: "1px 0 0",
								padding: "15px 10px",
							}}
						>
							<Pagination
								count={Math.ceil(count / REFUND_PAGINATION_LIMIT)}
								shape="rounded"
								onChange={handleChangePage}
							/>
						</Box>
					</Box>
				</Box>
			</Wrapper>
		</>
	);
}

// Styled Components

const Wrapper = styled(Container)(() => ({
	padding: "1rem",
	width: "100%",
	height: "calc(100vh - 60px)",
	overflow: "auto",
}));

export default RefundsList;
