import React, { useRef, useState } from 'react'
import { Box, Button, Grid, Typography, styled } from '@mui/material'

import AddPhoto from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from 'axios';
import { baseUrl } from '../../../../../../constants/api';

function DiscountBanner({ state, handleContentChange, removeSection }) {

    const token = window.localStorage.getItem("user-token");

    const logoUpload = useRef();

    const { banner } = state;

    const selectLogo = () => {
		logoUpload.current.click();
	};

    const [previewImage, setPreviewImg] = useState(null)

	const handleLogoUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setPreviewImg(URL.createObjectURL(file));
        }

        axios.put(`${baseUrl}set/single_banner`,
            {
                single_banner: file,
            },
            {
				headers: {
				  "Content-Type": "multipart/form-data",
				  Authorization: `Token ${token}`,
				},
			}
		)
		.then((response) => {
			handleContentChange('DiscountBanner', 'banner', response.data.single_banner);
		})
		.catch((err) => {
			console.log("Error", err)
		})
    };

	const removeImage = () => {
        setPreviewImg(null);
    };

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Typography sx={{fontSize: "16px", fontWeight: "600"}}>
                        Single Banner
                    </Typography>
                    <Typography sx={{fontSize: "12px", fontWeight: "400"}}>
                        This section features a single banner for sale or discount announcements, among other promotions.
                    </Typography>
                </Grid>
                <Uploader item md={1} onClick={() => selectLogo()}>
                    <input
                        type="file"
                        accept="image/*"
                        ref={logoUpload}
                        style={{ display: "none" }}
                        onChange={(e) => handleLogoUpload(e)}
                        id="logoInput"
                    />
                    <AddPhoto sx={{ fontSize: "25px" }} />
                    <Typography
                        sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
                    >
                        Upload Banner
                    </Typography>
                </Uploader>
                <Grid item md={10}>
                    {banner ? (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <ImgDisplay
                                sx={{
                                    background: `url(${previewImage}) center/cover no-repeat`,
                                }}
                            />
                            <CancelIcon
                                sx={{
                                    fontSize: "20px",
                                    position: "relative",
                                    right: "20px",
                                    cursor: "pointer",
                                }}
                                onClick={() => removeImage()}
                            />
                        </div>
                    ) : null}
                </Grid>
                <Grid item md={12} display={"flex"} justifyContent={"end"}>
                    <Button variant='contained' background="primary" sx={{marginTop: "15px", borderRadius: "10px",textTransform: "capitalize"}}
                        onClick={removeSection}
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

const Uploader = styled(Grid)(() => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "start",
	height: "90px",
	cursor: "pointer",
}));

const ImgDisplay = styled(Box)(() => ({
	width: "95%",
	height: "90px",
	backgroundColor: "#fff",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	margin: "10px",
	borderRadius: "10px",
}));

export default DiscountBanner