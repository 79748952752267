import React, { useEffect, useRef, useState } from "react";
import {
  styled,
  Container,
  Typography,
  Grid,
  Box,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import AddPhoto from "@mui/icons-material/AddPhotoAlternate";
import CancelIcon from "@mui/icons-material/Cancel";
import { MoonLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../constants/api";
import { useGetBlogsQuery } from "../../../../redux/api/blogApiSlice";
import Heading from "../../components/Heading";

const headerData = {
  heading: "Manage Marketplace Header",
  subheading: "",
};

function Header() {
  const logoUpload = useRef();
  const token = window.localStorage.getItem("user-token");
  const page = 1;
  const RowsPerPage = 5;

  const [logo, setLogo] = useState(null);
  const [previewImage, setPreviewImg] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [fileSize, setFileSize] = useState(0);

  const staticPages = [
    { name: "Home", route: "", active: false },
    { name: "Blogs", route: "blogs", active: false },
  ];

  const [enabledPages, setEnabledPages] = useState(staticPages);

  const selectLogo = () => {
    logoUpload.current.click();
  };

  const MAX_FILE_SIZE = 1000000; // 1MB in bytes
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFileSize(file?.size);
      setLogo(file);
      setPreviewImg(URL.createObjectURL(file));
    }
  };

  const removeImage = () => {
    setLogo(null);
    setPreviewImg(null);
    setFileSize(0);
  };

  const {
    data: getAllPages,
    isLoading: getAllPagesLoading,
    refetch: refetchBlogs,
  } = useGetBlogsQuery({ limit: RowsPerPage, offset: page, staticPage: true });

  useEffect(() => {
    refetchBlogs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (pageName) => {
    setEnabledPages((prev) => {
      const updatedPages = prev.map((page) =>
        page.name === pageName ? { ...page, active: !page.active } : page
      );
      return updatedPages;
    });
  };

  useEffect(() => {
    fetchHeaderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchHeaderData = () => {
    axios
      .get(`${baseUrl}get/header`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const enabledPagesFromApi =
          response.data.headers
            ?.filter((page) => page.active)
            .map((page) => page.name) || [];
        let activePages = staticPages.map((page) => ({
          ...page,
          active: enabledPagesFromApi.includes(page.name),
        }));

        if (!getAllPagesLoading && getAllPages?.results?.length > 0) {
          const dynamicPages = getAllPages.results.map((page) => ({
            name: page.en_title,
            route: page.slug,
            active: enabledPagesFromApi.includes(page.en_title),
            uuid: page.uuid,
          }));

          const existingPageNames = new Set(
            activePages.map((page) => page.name)
          );
          const newPages = dynamicPages.filter(
            (page) => !existingPageNames.has(page.name)
          );
          activePages = [...activePages, ...newPages];
        }

        setEnabledPages(activePages);
        setLogo(response.data.header_logo);
        setPreviewImg(response.data.header_logo);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const updateHeaderData = () => {
    setUpdating(true);
    const formData = new FormData();
    if (typeof logo !== "string") {
      formData.append("header_logo", logo);
    }
    formData.append("headers", JSON.stringify(enabledPages));
    axios
      .put(`${baseUrl}set/header`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        setUpdating(false);
        toast.success("Header settings have been successfully updated!");
        fetchHeaderData();
      })
      .catch((err) => {
        console.log("Error", err);
        setUpdating(false);
      });
  };

  return (
    <Wrapper maxWidth={false}>
      <Heading data={headerData} />

      <BoxWrapper my={2}>
        <Grid container mt={1}>
          <Grid item md={2}>
            <Box
              onClick={() => selectLogo()}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <HeadingText>Upload Your Logo</HeadingText>
              <input
                type="file"
                accept="image/*"
                ref={logoUpload}
                style={{ display: "none" }}
                onChange={(e) => handleLogoUpload(e)}
                id="logoInput"
              />
              <AddPhoto sx={{ fontSize: "25px" }} />
              <Typography
                sx={{ fontWeight: "500", fontSize: "12px", color: "#2A3F54" }}
              >
                Add Image
              </Typography>
            </Box>
          </Grid>
          <Grid item md={10}>
            {" "}
            {logo ? (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <ImgDisplay
                  sx={{
                    background: `url(${previewImage}) center/cover no-repeat`,
                  }}
                />
                <CancelIcon
                  sx={{
                    fontSize: "20px",
                    position: "relative",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeImage()}
                />
              </div>
            ) : null}
          </Grid>
          <Box>
            {fileSize > MAX_FILE_SIZE && (
              <Typography
                sx={{ color: "red", fontSize: "12px", fontWeight: "500" }}
              >
                File size should be less than 1MB
              </Typography>
            )}
          </Box>
        </Grid>
      </BoxWrapper>

      <BoxWrapper container my={2}>
        <HeadingText>Enable pages to show on Navbar</HeadingText>
        {getAllPagesLoading
          ? ""
          : enabledPages.map((page) => (
              <Grid
                container
                key={page.name}
                mt={1}
                display="flex"
                alignItems="center"
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={page.active}
                        onChange={() => handleCheckboxChange(page.name)}
                      />
                    }
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography>{page.name}</Typography>
                </Grid>
              </Grid>
            ))}
      </BoxWrapper>
      <Box
        mt={"50px"}
        gap={"30px"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button
          color="secondary"
          variant="outlined"
          style={{ textTransform: "none", width: "100px" }}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          style={{ textTransform: "none", width: "100px" }}
          disabled={fileSize > MAX_FILE_SIZE}
          onClick={() => updateHeaderData()}
        >
          {updating ? <MoonLoader color="#fff" size={20} /> : "Save"}
        </Button>
      </Box>
    </Wrapper>
  );
}

// Styled Components
const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const HeadingText = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const ImgDisplay = styled(Box)(() => ({
  width: "100px",
  height: "90px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  borderRadius: "10px",
}));

const BoxWrapper = styled(Grid)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

export default Header;
