import { Box, Grid, Slider, Typography } from "@mui/material";
import React from "react";
import HeadingOneImg from "../../../../../assets/HeadingOneImg.png";
import HeadingTwoImg from "../../../../../assets/HeadingTwoImgA.svg";

const HeadingOnePageName = [
  {
    id: 1,
    name: "Landing Page (every section, such as)",
    points: [
      {
        id: 1,
        name: "Best Sellers",
      },
      {
        id: 2,
        name: "Categories",
      },
      {
        id: 3,
        name: "Services",
      },
    ],
  },
  {
    id: 2,
    name: "Single Blog View",
    points: [
      {
        id: 1,
        name: "In the Single Blog View, the Heading One is used as the title of the blog.",
      },
    ],
  },
  {
    id: 3,
    name: "Static Pages (such as Return Policy, Privacy Policy, Terms of Service, etc.)",
    points: [
      {
        id: 1,
        name: "On Static Pages, the Heading One is used as the title of the page.",
      },
    ],
  },
];

const HeadingTwoPageName = [
  {
    id: 1,
    name: "Product Detail Page",
    points: [
      {
        id: 1,
        name: "On the Product Details Page, the Heading Two is used to display the name of the product.",
      },
    ],
  },
  {
    id: 2,
    name: "Cart Page",
    points: [
      {
        id: 1,
        name: "On the Cart Page, the Heading Two is used to display the Cart Totals section.",
      },
    ],
  },
  {
    id: 3,
    name: "Checkout Page",
    points: [
      {
        id: 1,
        name: "On the Checkout Page, the Heading Two is used to display the Order Summary section.",
      },
    ],
  },
];

const HeadingOneTwo = ({
  headingOne,
  setHeadingOne,
  headingTwo,
  setHeadingTwo,
  two,
}) => {
  const pageName = two ? HeadingTwoPageName : HeadingOnePageName;
  return (
    <>
      <Grid
        container
        mt={1}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item md={3}>
          <Typography
            sx={{
              fontSize: two ? `${headingTwo}px` : `${headingOne}px`,
              fontWeight: "600",
            }}
          >
            {two ? "Heading Two" : "Heading One"}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Slider
              max={50}
              valueLabelDisplay="auto"
              value={two ? headingTwo : headingOne}
              onChange={(e) =>
                two
                  ? setHeadingTwo(e.target.value)
                  : setHeadingOne(e.target.value)
              }
            />

            <Box
              sx={{
                fontSize: "16px",
              }}
            >
              ({two ? `${headingTwo}px` : `${headingOne}px`})
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* bullets */}
      <Grid
        container
        mt={1}
        sx={{
          display: "flex",
        }}
      >
        <Grid item md={6}>
          <Typography
            sx={{
              fontWeight: "600",
            }}
          >
            {/* Implement on these pages */}
          </Typography>
          <Box
            sx={{
              margin: "10px 20px",
            }}
          >
            {pageName?.map((item) => {
              return (
                <Box key={item.id}>
                  <ul>
                    <li>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {item.name}
                      </Typography>
                      {item.points && item.points.length > 0 && (
                        <ul>
                          {item.points.map((point) => (
                            <li key={point.id}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                {point.name}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  </ul>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "15px 15px 12px 15px",
            }}
          >
            <img
              src={two ? HeadingTwoImg : HeadingOneImg}
              alt="heading"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadingOneTwo;
