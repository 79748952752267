import React,{useState} from 'react';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// React Toastify Imports
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Loader Import
import { MoonLoader } from 'react-spinners';

// MUI Componenets Import
import { Container, Grid, Box, Typography, TextField, Button } from '@mui/material';

// Assets Import
import background from "../../assets/background.webp";
import logo from "../../assets/logo.webp";
import { baseUrl } from '../../constants/api';

function ForgotPassword() {

    const navigate = useNavigate()

    
    const [navigating, setNavigating] = useState(false)
    const [email, setEmail] = useState('')
    
    const verifyEmail = () => {
        setNavigating(true)
        axios.post(`${baseUrl}auth/forgot`,
            {
                username: email,
            },
        )
        .then((response) => {
            window.localStorage.setItem("forget-email", email)
            toast.success(response.data.message);
            setTimeout(() => {
                setNavigating(false)
                navigate('/reset-password');
            }, 2000);
        })
        .catch((err) => {
            if (err.response) {
                toast.error(err.response.data.message)
            }
            console.log("error", err)   
            setNavigating(false)
        })
    }

    return (
        <Wrapper maxWidth={false}>
            <Grid container display={"flex"} justifyContent={"center"}>
                <FormContainer item lg={8} md={8} sm={8} xs={12}>
                    <FormWrapper>
                        <img src={logo} alt='logo' style={{maxWidth: "100%", height: "100px"}} />
                        <Heading>Forgot your password?</Heading>
                        <Text>Enter the email address associated with your account</Text>
                        <Box py={2}>
                            <Input type="email" label="Email" fullWidth color="primary" 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                        </Box>
                        <Button sx={{ width: '100%',textTransform: 'none'}} variant="contained" color="secondary"
                            onClick={() => verifyEmail()}
                        >
                            {navigating ? (
                                <>                                    
                                    <MoonLoader color="#fff" size={20} />
                                </>
                                ) : (
                                "Send Code"
                            )}
                        </Button> 
                    </FormWrapper>
                </FormContainer>
            </Grid>
        </Wrapper>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "0 !important",
    background: `url(${background}) center/cover no-repeat`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
}))

const FormContainer = styled(Grid)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
}))

const FormWrapper = styled(Box)(({ theme }) => ({
    maxWidth: "450px",
    minWidth: "320px",
    padding: "20px 50px",
    textAlign: "center",
    background: 'white',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        width: '310px',
        padding: '20px'
    }
}))

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontWeight: "700",
    color: "#2A3342",
    [theme.breakpoints.down('sm')]: {
        fontSize: "14px",
    },
}))

const Text = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "400",
    color: "#556987",
    [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
    },
}))

const Input = styled(TextField)(() => ({
    width: "100%",
    marginBottom: "15px"
}))

export default ForgotPassword