import React, { useState } from 'react'
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container, Typography, Switch, TextField, Select, Button, Box, Grid, FormControl, MenuItem } from '@mui/material'

// Icons Import
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

// React Toastify Imports
import {   toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProductWarehouses({ productWarehouses, onUpdateWarehouses, warehousesList, onAddClick }) {

    const [isSwitchOn, setIsSwitchOn] = useState(productWarehouses?.length > 0 ? true : false);
    const [warehouse, setWarehouse] = useState(productWarehouses?.length > 0 ? productWarehouses : []);

    const isIdInWarehouse = (id) => {
        return warehouse.some((warehouseItem) => warehouseItem.warehouseId === id);
    };
    
    const handleAddClick = () => {
        onAddClick(true);
    };

    const handleAddWarehouse = () => {
        const newWarehouse = {
            warehouseId: '',
            warehouseStock: '',
        };
        const updatedWarehouses = [...warehouse, newWarehouse];
        setWarehouse(updatedWarehouses);
        onUpdateWarehouses(updatedWarehouses);
    };

    const handleWarehouseChange = (index, key, value) => {
        if(isIdInWarehouse(value)){
            toast.error("Warehouse already added!");
            return;
        }
        const updatedWarehouses = [...warehouse];

        if (key === 'warehouseStock' ) {
            value = value.replace(/,/g, '');
            if (value === '' || /^\d{0,10}(\.\d{0,2})?$/.test(value)) {
                const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                updatedWarehouses[index][key] = formattedValue;
            }
        } else {
            updatedWarehouses[index][key] = value;
        }

        setWarehouse(updatedWarehouses);
        onUpdateWarehouses(updatedWarehouses);
    };

    const handleBlur = (index) => {
        const updatedWarehouses = [...warehouse];
        const value = updatedWarehouses[index].warehouseStock;
    
        if (value && !/\./.test(value)) {
            updatedWarehouses[index].warehouseStock = value + ".00";
            setWarehouse(updatedWarehouses);
            onUpdateWarehouses(updatedWarehouses);
        }
    };

    const handleDeleteWarehouse = (index) => {
        const updatedWarehouses = [...warehouse];
        updatedWarehouses.splice(index, 1);
        setIsSwitchOn(updatedWarehouses.length > 0);
        setWarehouse(updatedWarehouses);
    
        onUpdateWarehouses(updatedWarehouses);
    };
    return (
        <>
            <Wrapper maxWidth={false}>
                <Box display={"flex"} alignItems={"center"} gap={3}>
                    <Heading>Warehouses</Heading>
                    <Switch
                        size="medium"
                        color="secondary"
                        checked={isSwitchOn}
                        onChange={() => setIsSwitchOn(!isSwitchOn)}
                    />
                </Box>  
                {isSwitchOn && (
                    <WarehouseWrapper container>
                        {warehouse.map((_, index) => (
                            <Box key={index} sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"}}>
                                <Grid item md={2}>
                                    <Label>Warehouse:</Label>
                                    <FormControl size="small" sx={{ width: '100%', mt: '5px', backgroundColor: '#fff', borderRadius: '12px'}}>
                                        <Select
                                            sx={{borderRadius: "12px"}}
                                            defaultValue={warehouse[index].warehouseId}
                                            value={warehouse[index].warehouseId} // Set the selected UUID here
                                            onChange={(e) => handleWarehouseChange(index, 'warehouseId', e.target.value)}>
                                            {warehousesList.map((warehouseItem) => (
                                                <MenuItem key={warehouseItem.uuid} value={warehouseItem.uuid}>
                                                    {warehouseItem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={2}>
                                    <Label>Stock:</Label>
                                    <Input mt={3} type="text" size="small" color='success' variant="outlined"
                                        InputProps={{ sx: { borderRadius: 3 } }}
                                        value={warehouse[index].warehouseStock} 
                                        onChange={(e) => handleWarehouseChange(index, 'warehouseStock', e.target.value)}
                                        onBlur={() => handleBlur(index)}
                                    />
                                </Grid>
                                <Grid item md={1}>
                                    <Label>Action</Label>
                                    <DeleteIcon
                                        sx={{ fontSize: '25px', cursor: 'pointer' }}
                                        onClick={() => handleDeleteWarehouse(index)}
                                    />
                                </Grid>
                            </Box>
                        ))}
                        <Grid item md={12}>
                            <Button onClick={handleAddWarehouse}>Add</Button>
                            <Button onClick={handleAddClick}>Create</Button>
                        </Grid>
                    </WarehouseWrapper>
                )}
            </Wrapper>
        </>
    )
}

// Styled Components

const Wrapper = styled(Container)(() => ({
    padding: "0 !important"
}))

const Heading = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "600",
}));

const WarehouseWrapper = styled(Grid)(() => ({
    height: 'auto', 
    borderRadius: '10px', 
    display: 'flex', 
    flexDirection: 'row', 
    gap: '10px', 
    backgroundColor: 'none',  
    alignItems: 'center'
}))

const Label = styled(Typography)(({
    fontSize: "12px",
    fontWeight: "500"
}))

const Input = styled(TextField)(({
    marginTop: "5px",
    background: "#fff",
    borderRadius: "12px"
}))

export default ProductWarehouses