import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { MoonLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { hexToRgba } from "../../../../utils";
import HeaderBG from "./components/HeaderBG";
import FooterBG from "./components/FooterBG";
import HeadingOneTwo from "./components/HeadingOneTwo";
import HeadingThreePara from "./components/HeadingThreePara";
import { FaRegCheckCircle } from "react-icons/fa";
import { baseUrl } from "../../../../constants/api";
import { useGetStylesQuery } from "../../../../redux/api/cmsApiSlice";
import Heading from "../../components/Heading";


const headerData = {
  heading: "Set Header Styles",
  subheading: "",
};

const headerDataOne = {
  heading: "Set Footer Styles",
  subheading: "",
};

const headerDataTwo = {
  heading: "Set Button Styles",
  subheading: "",
};

const fontStyle = {
  heading: "Set Font Styles",
};

const colors = ["#85c2b7", "#FFC107", "#00acc1", "#fe9511", "#d49e6e"];

function Styling() {
  const token = window.localStorage.getItem("user-token");

  const [reloading, setReloading] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);

  const [headerBG, setHeaderBg] = useState("#d49e6e");
  const [headerText, setHeaderText] = useState("#FFFFFF");
  const [footerBg, setFooterBg] = useState("#d49e6e");
  const [footerText, setFooterText] = useState("#FFFFFF");
  const [buttonsBg, setButtonsBg] = useState("#d49e6e");
  const [buttonsText, setButtonsText] = useState("#FFFFFF");
  const [headingOne, setHeadingOne] = useState(36);
  const [headingTwo, setHeadingTwo] = useState(30);
  const [headingThree, setHeadingThree] = useState(24);
  const [text, setText] = useState(16);
  const [fontFamily, setFontFamily] = useState("DM Sans");
  const [stylesData, setStylesData] = useState([]);

  const fonts = [
    "DM Sans",
    "Roboto",
    "Open Sans",
    "Lato",
    "Montserrat",
    "Poppins",
    "Raleway",
    "Nunito",
  ];

  // todo: GET ALL STYLES API CALL
  const {
    data: getAllStyles,
    isLoading: getAllStylesLoading,
    refetch: refetchGetAllStyles,
  } = useGetStylesQuery({});

  useEffect(() => {
    if (getAllStyles) {
      const headerBG = getAllStyles.header_background;
      const FooterBG = getAllStyles.footer_background;
      const ButtonsBG = getAllStyles.button_color;

      if (headerBG === FooterBG && FooterBG === ButtonsBG) {
        setSelectedColor(ButtonsBG);
      } else {
        setSelectedColor(null);
      }

      setStylesData(getAllStyles);
      setHeaderBg(getAllStyles.header_background);
      setHeaderText(getAllStyles.header_text_color);
      setFooterBg(getAllStyles.footer_background);
      setFooterText(getAllStyles.footer_text_color);
      setButtonsBg(getAllStyles.button_color);
      setButtonsText(getAllStyles.button_text_color);
      setHeadingOne(getAllStyles.h1_font_size);
      setHeadingTwo(getAllStyles.h2_font_size);
      setHeadingThree(getAllStyles.h3_font_size);
      setText(getAllStyles.p_font_size);
      setFontFamily(getAllStyles.font_family);
    }
  }, [getAllStyles]);

  const updateUserStyles = async () => {
    setReloading(true);

    try {
      const response = await axios.post(
        `${baseUrl}set/styles`,
        {
          header_background: headerBG,
          header_text_color: headerText,
          footer_background: footerBg,
          footer_text_color: footerText,
          button_color: buttonsBg,
          button_text_color: buttonsText,
          h1_font_size: headingOne,
          h2_font_size: headingTwo,
          h3_font_size: headingThree,
          p_font_size: text,
          font_family: fontFamily,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Styles have been saved!");
        refetchGetAllStyles();
      }
    } catch (error) {
      toast.error("Couldn't save the styles!");
    } finally {
      setReloading(false);
    }
  };

  const hoverColorRgba = hexToRgba(buttonsBg);

  const handleColorClick = (color) => {
    setSelectedColor(color);

    setButtonsBg(color);
    setHeaderBg(color);
    setFooterBg(color);
  };

  const handleClearClick = () => {
    setSelectedColor(null);

    setButtonsBg(stylesData?.button_color);
    setHeaderBg(stylesData?.header_background);
    setFooterBg(stylesData?.footer_background);
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              alignItems: "end",
            }}
          >
            <Heading data={headerData} />
          </Box>
          <Box
            sx={{
              width: "50%",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fefefe",
                padding: "15px 20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginBottom: "15px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                }}
              >
                Some Recommended Theme Colors for the Market Place
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#757575",
                }}
              >
                It will apply for the Header, Footer, and Buttons
              </Typography>

              <Box sx={{ marginTop: "15px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {colors.map((color) => (
                      <Box
                        key={color}
                        sx={{
                          backgroundColor: color,
                          width: "50px",
                          height: "50px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleColorClick(color)}
                      >
                        {selectedColor === color && (
                          <Box
                            sx={{
                              display: "flex",

                              color: "white",
                              width: "20px",
                              height: "20px",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            <FaRegCheckCircle />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={handleClearClick}
                    sx={{ marginTop: "10px" }}
                  >
                    Clear
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <BoxWrapper container my={2}>
          <ColorBox item md={3}>
            <HeadingText>Header Background Color</HeadingText>
            <input
              type="color"
              value={headerBG}
              onChange={(e) => setHeaderBg(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{headerBG}</Typography>
          </ColorBox>

          <ColorBox item md={3}>
            <HeadingText>Header Text Color</HeadingText>
            <input
              type="color"
              value={headerText}
              onChange={(e) => setHeaderText(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{headerText}</Typography>
          </ColorBox>
          <ColorBox item md={3}>
            <HeaderBG bg={headerBG} textColor={headerText} />
          </ColorBox>
        </BoxWrapper>
        {/* Footer */}
        <Heading data={headerDataOne} />
        <BoxWrapper container my={2}>
          <ColorBox item md={3}>
            <HeadingText>Footer Background Color</HeadingText>
            <input
              type="color"
              value={footerBg}
              onChange={(e) => setFooterBg(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{footerBg}</Typography>
          </ColorBox>

          <ColorBox item md={3}>
            <HeadingText>Footer Text Color</HeadingText>
            <input
              type="color"
              value={footerText}
              onChange={(e) => setFooterText(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{footerText}</Typography>
          </ColorBox>
          <ColorBox item md={3}>
            <FooterBG bg={footerBg} textClr={footerText} />
          </ColorBox>
        </BoxWrapper>
        {/* Buttons */}
        <Heading data={headerDataTwo} />
        <BoxWrapper container my={2}>
          <ColorBox item md={3}>
            <HeadingText>Buttons Background Color</HeadingText>
            <input
              type="color"
              value={buttonsBg}
              onChange={(e) => setButtonsBg(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{buttonsBg}</Typography>
          </ColorBox>
          <ColorBox item md={3}>
            <HeadingText>Buttons Text Color</HeadingText>
            <input
              type="color"
              value={buttonsText}
              onChange={(e) => setButtonsText(e.target.value)}
              style={{ width: "100%" }}
            />
            <Typography>{buttonsText}</Typography>
          </ColorBox>

          <ColorBox item md={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                fullWidth
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: buttonsBg,
                  color: buttonsText,
                  "&:hover": {
                    backgroundColor: buttonsBg,
                  },
                }}
              >
                Button Text
              </Button>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: buttonsBg,
                  borderColor: buttonsBg,
                  "&:hover": {
                    borderColor: buttonsBg,
                    background: hoverColorRgba,
                  },
                }}
              >
                Button Text
              </Button>
            </Box>
          </ColorBox>
        </BoxWrapper>

        {/* Font Family */}
        <Heading data={fontStyle} />

        <SectionWrapper mt={3}>
          <Grid
            container
            mt={1}
            spacing={3}
            sx={{
              padding: "0px 22px",
            }}
          >
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="font-select-label">Font Family</InputLabel>
                <Select
                  labelId="font-select-label"
                  value={fontFamily}
                  onChange={(e) => setFontFamily(e.target.value)}
                  label="Font Family"
                >
                  {fonts.map((font) => (
                    <MenuItem
                      key={font}
                      value={font}
                      style={{ fontFamily: font }}
                    >
                      {font}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily,
                }}
              >
                CR#: 1234569
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily,
                }}
              >
                Your one stop shop for everything you need.
              </Typography>
            </Grid>
          </Grid>
        </SectionWrapper>

        {/* 1st & 2d */}
        <SectionWrapper mt={3}>
          <HeadingOneTwo
            headingOne={headingOne}
            setHeadingOne={setHeadingOne}
          />
        </SectionWrapper>

        <SectionWrapper mt={3}>
          <HeadingOneTwo
            headingTwo={headingTwo}
            setHeadingTwo={setHeadingTwo}
            two={true}
          />
        </SectionWrapper>
        {/* 1st & 2d End */}

        {/* 3rd */}
        <SectionWrapper mt={3}>
          <HeadingThreePara
            headingThree={headingThree}
            setHeadingThree={setHeadingThree}
          />
        </SectionWrapper>

        <SectionWrapper mt={3}>
          <HeadingThreePara text={text} setText={setText} two={true} />
        </SectionWrapper>
        {/* 3rd End */}

        <Box
          mt={"50px"}
          gap={"30px"}
          width={"100%"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button
            color="secondary"
            variant="contained"
            style={{ textTransform: "none", width: "100px" }}
            onClick={() => updateUserStyles()}
          >
            {reloading || getAllStylesLoading ? (
              <>
                <MoonLoader color="#fff" size={20} />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </Box>
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const HeadingText = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const BoxWrapper = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "30px",
  height: "auto",
  padding: "42px",
  borderRadius: "8px",
  backgroundColor: "white",
}));

const ColorBox = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: "10px",
}));

const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  fontSize: "22px",
  fontWeight: "600",
}));

export default Styling;
