import {
  Container,
  Box,
  Button,
  Grid,
  Tooltip,
  Pagination,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import {
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useGetBlogsQuery,
  useUpdateBlogMutation,
} from "../../../../redux/api/blogApiSlice";
import { MoonLoader, BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import MUITable, {
  StyledTableCell,
  StyledTableRow,
} from "../../../../components/MUITable";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/BorderColor";
import Heading from "../../components/Heading";
import TextEditor from "../../../../components/TextEditor"
import { SubHeading } from "../../../../components/Heading";
import PrimaryInput from "../../../../components/PrimaryInput/PrimaryInput";
import Loader from "../../../Loaders/Loader";
import { truncateString } from "../../../../utils";

const headerData = {
  heading: "Create Blogs",
  subheading: "",
};

const tableHead = [
  "#",
  "Title (Eng)",
  "Content (Eng)",
  "Title (Arabic)",
  "Content (Arabic)",
  "Publish",
  "Actions",
];
const Blogs = () => {
  const [page, setPage] = useState(1);
  const RowsPerPage = 5;
  const [editBlog, setEditBlog] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [arabicTitle, setArabicTitle] = useState("");
  const [arabicContent, setArabicContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);

  // todo: GET ALL BLOGS API CALL
  const {
    data: getAllBlogs,
    isLoading: getAllBlogsLoading,
    isSuccess,
  } = useGetBlogsQuery({ limit: RowsPerPage, offset: page });

  // todo: CREATE BLOG API BIND
  const [createBlog, { isLoading: createBlogLoading }] =
    useCreateBlogMutation();

  // todo: UPDATE BLOG API BIND
  const [updateBlog, { isLoading: updateBlogLoading }] =
    useUpdateBlogMutation();

  const createBlogHandle = async () => {
    if (blogTitle === "" || blogTitle.trim() === "") {
      return toast.warn("Please enter blog title in English");
    }

    if (arabicTitle === "" || arabicTitle.trim() === "") {
      return toast.warn("Please enter static page title in Arabic");
    }

    const cleanedValue1 = blogContent.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
    const cleanedValue2 = arabicContent.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags

    if (!cleanedValue1 || cleanedValue1.trim() === "") {
      toast.warning(
        "Please enter some text to save the blog content in English"
      );
      return;
    }

    if (!cleanedValue2 || cleanedValue2.trim() === "") {
      toast.warning(
        "Please enter some text to save the static page content in Arabic"
      );
      return;
    }

    const payload = {
      en_title: blogTitle,
      en_content: blogContent,
      ar_title: arabicTitle,
      ar_content: arabicContent,
    };

    if (editBlog) {
      try {
        const create = await updateBlog({
          id: editBlog,
          body: payload,
        });

        if (!create?.error) {
          toast.success("Blog updated successfully!");
          setBlogTitle("");
          setBlogContent("");
          setEditBlog(null);
          setArabicTitle("");
          setArabicContent("");
        }

        if (create?.error) {
          toast.error("Something went wrong", "error");
        }
      } catch (error) {
        console.error("Update Blog Error:", error);
        toast.error(error.response.data.message);
      }

      return;
    }

    try {
      const create = await createBlog({ body: payload });

      if (!create?.error) {
        toast.success("Blog created successfully!");
        setBlogTitle("");
        setBlogContent("");
        setEditBlog(null);
        setArabicTitle("");
        setArabicContent("");
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Create Blog Error:", error);
      toast.error(error.response.data.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // todo: DELETE BLOG API BIND
  const [deleteBlog, { isLoading: deleteLoading }] = useDeleteBlogMutation();

  const deleteBlogHandle = async (id) => {
    try {
      const deleteCharge = await deleteBlog({ id });

      if (!deleteCharge?.error) {
        toast.success("Blog deleted successfully!");
        setBlogTitle("");
        setBlogContent("");
        setEditBlog(null);
        setArabicTitle("");
        setArabicContent("");
      }
      if (deleteCharge?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Blog Deleted Type Error:", error);
      toast.error(error.response.data.message);
    }
  };

  // todo: STATUS UPDATE BLOG API BIND
  const [statusUpdateBlog, { isLoading: statusUpdateBlogLoading }] =
    useUpdateBlogMutation();

  const setBlogStatus = async (state, id) => {
    const payload = {
      is_active: state,
    };

    try {
      const create = await statusUpdateBlog({
        id,
        body: payload,
      });

      if (!create?.error) {
        toast.success(
          `Blog ${state ? "published" : "unpublished"} successfully!`
        );
        setEditBlog(null);
      }

      if (create?.error) {
        toast.error("Something went wrong", "error");
      }
    } catch (error) {
      console.error("Status Update Blog Error:", error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />

        <Box
          sx={{
            margin: "20px 0",
          }}
        >
          <SectionWrapper mt={3}>
            <Grid
              container
              spacing={3}
              sx={{
                display: "flex",
              }}
            >
              <Grid item md={6}>
                <Box
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
                    Blog Title
                  </SubHeading>
                  <PrimaryInput
                    value={blogTitle}
                    onChange={(e) => setBlogTitle(e.target.value)}
                    label=""
                    placeholder="Enter Blog Title"
                    borderRadius="0"
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
                      Blog Content
                    </SubHeading>
                    {loading && (
                      <Box sx={{ marginBottom: "5px" }}>
                        <Box
                          sx={{
                            fontSize: "12px",
                            marginBottom: "5px",
                          }}
                        >
                          Image Uploading...
                        </Box>
                        <BarLoader color="#812a91" size={30} />
                      </Box>
                    )}
                  </Box>

                  <TextEditor
                    value={blogContent}
                    onChange={(value) => setBlogContent(value)}
                    placeholder="Write something awesome..."
                    setLoading={setLoading}
                  />
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
                    Blog Title (Arabic)
                  </SubHeading>
                  <PrimaryInput
                    value={arabicTitle}
                    onChange={(e) => setArabicTitle(e.target.value)}
                    label=""
                    placeholder="Enter Blog Title (Arabic)"
                    borderRadius="0"
                  />
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <SubHeading sx={{ marginBottom: "5px", fontSize: "12px" }}>
                      Blog Content (Arabic)
                    </SubHeading>
                    {loadingTwo && (
                      <Box sx={{ marginBottom: "5px" }}>
                        <Box
                          sx={{
                            fontSize: "12px",
                            marginBottom: "5px",
                          }}
                        >
                          Image Uploading...
                        </Box>
                        <BarLoader color="#812a91" size={30} />
                      </Box>
                    )}
                  </Box>

                  <TextEditor
                    value={arabicContent}
                    onChange={(value) => setArabicContent(value)}
                    placeholder="Write something awesome... (Arabic)"
                    setLoading={setLoadingTwo}
                  />
                </Box>
              </Grid>

              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <SaveBTN onClick={createBlogHandle}>
                  {createBlogLoading || updateBlogLoading ? (
                    <>
                      <MoonLoader color="#fff" size={20} />
                    </>
                  ) : editBlog ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </SaveBTN>
              </Grid>
            </Grid>
          </SectionWrapper>
        </Box>

        <Box>
          {getAllBlogsLoading && <Loader />}
          <Box
            sx={{
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.16) 3px 16px 87px 0px",
            }}
          >
            <MUITable tableHead={tableHead}>
              {isSuccess && getAllBlogs?.results?.length > 0 ? (
                getAllBlogs?.results?.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={row?.uuid}
                      sx={{
                        borderBottomLeftRadius: "0",
                        borderBottom: "100px",
                      }}
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{row?.en_title}</StyledTableCell>
                      <StyledTableCell>
                        {truncateString(row?.en_content, 30)}
                      </StyledTableCell>

                      <StyledTableCell>{row?.ar_title}</StyledTableCell>
                      <StyledTableCell>
                        {truncateString(row?.ar_content, 30)}
                      </StyledTableCell>

                      <StyledTableCell>
                        {statusUpdateBlogLoading &&
                        row?.uuid === selectedBlog ? (
                          <Box
                            sx={{
                              marginLeft: "16px",
                            }}
                          >
                            <MoonLoader color="#000" size={16} />
                          </Box>
                        ) : (
                          <Tooltip
                            title={row?.is_active ? "Unpublished" : "Published"}
                            placement="top"
                          >
                            <Switch
                              checked={row?.is_active}
                              onClick={() => {
                                setSelectedBlog(row?.uuid);
                                setBlogStatus(!row?.is_active, row?.uuid);
                              }}
                            />
                          </Tooltip>
                        )}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                          }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <EditIcon
                              sx={{ cursor: "pointer", color: "#07bc0c" }}
                              onClick={() => {
                                setEditBlog(row?.uuid);
                                setBlogTitle(row?.en_title);
                                setBlogContent(row?.en_content);
                                setArabicTitle(row?.ar_title);
                                setArabicContent(row?.ar_content);
                              }}
                            />
                          </Tooltip>
                          {deleteLoading && row?.uuid === selectedBlog ? (
                            <MoonLoader color="#000" size={16} />
                          ) : (
                            <Tooltip title="Delete" placement="top">
                              <DeleteIcon
                                sx={{ cursor: "pointer", color: "red" }}
                                onClick={() => {
                                  setSelectedBlog(row?.uuid);
                                  deleteBlogHandle(row?.uuid);
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    sx={{ height: "100px" }}
                    colSpan={tableHead?.length}
                    align="center"
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                      }}
                    >
                      {getAllBlogs?.results?.length === 0
                        ? "No records found"
                        : ""}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </MUITable>
            <Box
              sx={{
                background: "#fff",
                display: "flex",
                justifyContent: "end",
                // margin: "1px 0 0",
                padding: "15px 10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#49464f",
                  marginRight: "10px",
                }}
              >
                Total Records: {getAllBlogs?.total_count}
              </Box>
              <Pagination
                count={Math.ceil(getAllBlogs?.total_count / RowsPerPage)}
                shape="rounded"
                onChange={handleChangePage}
              />
            </Box>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  fontSize: "22px",
  fontWeight: "600",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "160px",
  // padding: "10px 24px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: "#812a90",
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#812a90",
  },
}));

export default Blogs;
