import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { baseUrl } from "../../constants/api";
import axios from "axios";

// MUI Components Import
import { Container, Box, Grid, Button, Typography, InputAdornment, IconButton, TextField } from "@mui/material";

// Loader Import
import { MoonLoader } from 'react-spinners';

// React Toastify Imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// MUI Icons Import
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import RoomRoundedIcon from "@mui/icons-material/RoomRounded";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import MoneyIcon from "@mui/icons-material/Money";
import { EditRounded } from "@mui/icons-material";

// Date Pickers Import
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Components Import
import Heading from "../Dashboards/components/Heading";

// Day JS Import
import dayjs from "dayjs";

const headerData = {
    heading: "Profile",
    subheading: "",
};

const Dashboard = () => {

    const token = window.localStorage.getItem("user-token");

    const imgUpload = useRef();

	const selectFiles = () => {
		imgUpload.current.click();
	};

    const [uploading, setUploading] = useState(false)
    const [passwordData, setPasswordData] = useState({
        current: "",
        new: "",
        confirm: "",
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [updatingPass, setUpdatingPass] = useState(false)

    const [companyName, setCompanyName] = useState()
    const [companyEmail, setCompanyEmail] = useState()
    const [companyNumber, setCompanyNumber] = useState()
    const [website, setWebsite] = useState()
    const [companyAddress, setCompanyAddress] = useState()
    const [crNumber, setCrNumber] = useState()
    const [expiryDate, setExpiryDate] = useState()
    const [bankName, setBankName] = useState()
    const [bankBranchCode, setBankBranchCode] = useState()
    const [bankIBAN, setBankIBAN] = useState()
    const [bankAddress, setBankAddress] = useState()
    const [profileImage, setProfileImage] = useState()
	const [previewImage, setPreviewImg] = useState(null);

    const PasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const NewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const ConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    const fetchUserData = () => {
        axios.get(`${baseUrl}auth/profile`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        ).then((response) => {
            setCompanyName(response.data.company_name)
            setCompanyEmail(response.data.company_email)
            setCompanyNumber(response.data.company_number)
            setCompanyAddress(response.data.company_address)
            setWebsite(response.data.website)
            setCrNumber(response.data.cr_number)
            setExpiryDate(response.data.expiry_date)
            setBankName(response.data.bank_name)
            setBankBranchCode(response.data.bank_branch_code)
            setBankIBAN(response.data.bank_iban)
            setBankAddress(response.data.bank_address)
            setProfileImage(response.data.profileImage)
            setPreviewImg(response.data.profileImage)
        })
            .catch((err) => {
                console.log(err)
            })
    };

    const updateUserData = () => {
        
        const formData = new FormData();

		formData.append("company_name", companyName);
		formData.append("company_email", companyEmail);
		formData.append("company_number", companyNumber);
		formData.append("company_address", companyAddress);
		formData.append("website", website);
		formData.append("cr_number", crNumber);
		formData.append("expiry_date", expiryDate);
        formData.append("bank_name", bankName);
        formData.append("bank_branch_code", bankBranchCode);
        formData.append("bank_iban", bankIBAN);
        formData.append("bank_address", bankAddress);
		if (typeof profileImage !== "string") {
			formData.append("profileImage", profileImage);
		}

        setUploading(true)

        axios.put(`${baseUrl}auth/profile`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}`,
                },
            }
        ).then((response) => {
            toast.success("Profile updated successfully!");
            setUploading(false)
            window.location.reload();
        }).catch((err) => {
            if (err.response) {
              if (err.response.data) {
                const errorMessages = [];
                for (const key in err.response.data) {
                  if (Array.isArray(err.response.data[key])) {
                    errorMessages.push(...err.response.data[key]);
                    toast.error(`${key} ${err.response.data[key][0]}`);
                  }
                }
                if (errorMessages.length > 0) {
                } else {
                  toast.error(err.response.data.message);
                }
              } else {
                toast.error(err.response.data.message || "Failed to update profile!");
              }
            } else {
              toast.error("An error occurred. Please try again.");
            }
            setUploading(false);
          });
    }

    const changeUserPassword = () => {
        // Check if new password and confirm password are the same
        if (passwordData.new !== passwordData.confirm) {
            toast.error("New password and confirm password must match.");
            return;
        }

        axios.post(
            `${baseUrl}auth/change-password`,
            {
                old_password: passwordData.current,
                new_password: passwordData.new
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            }
        ).then(() => {
            toast.success("Password updated successfully!");

            // Clear the password fields after successful update
            setPasswordData({
                current: "",
                new: "",
                confirm: "",
            });
        }).catch((err) => {
            if (err.response && err.response.data) {
                const errorMessage = err.response.data.message || "Failed to update password";

                // Check if the error message is "Invalid old password"
                if (errorMessage === "Invalid old password.") {
                    toast.error("Invalid old password. Please enter the correct current password.");
                } else {
                    toast.error(errorMessage);
                }
            } else {
                toast.error("Failed to update password!");
            }
            setUpdatingPass(false);
        });
    };

    const handleCompanyLogo = (e) => {
        const file = e.target.files[0];
		if (file) {
			setProfileImage(file);
			setPreviewImg(URL.createObjectURL(file));
		}
    };

    useEffect(() => {
        fetchUserData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Wrapper maxWidth={false}>
                <Heading data={headerData} />
                <Grid container my={3} sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Card item md={5.8}>
                        <CardHeading>Company Details</CardHeading>
                        <Grid my={2} sx={{ display: "flex", justifyContent: "space-between", gap:"15px" }}>
                            {profileImage ? (
                                <Box
                                    onClick={() => selectFiles()}
                                    sx={{
                                        width: "140px",
                                        height: "130px",
                                        backgroundColor: "#CECECE",
                                        borderRadius: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        background: `url(${previewImage}) center/cover no-repeat`,
                                        cursor: "pointer"
                                    }}
                                >
                                    <input
                                        type="file"
									    accept="image/*"
									    ref={imgUpload}
									    style={{ display: "none" }}
									    onChange={(e) => handleCompanyLogo(e)}
									    id="imageInput"
                                    />
                                </Box>
                            ) : null}
                            <Grid container>
                                <Grid item md={12}>
                                    <Label>Company Name</Label>
                                    <Input
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Label>Company Email</Label>
                                    <Input
                                        type="email"
                                        value={companyEmail}
                                        onChange={(e) => setCompanyEmail(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <Label>Company Number</Label>
                            <Input
                                value={companyNumber}
                                onChange={(e) => setCompanyNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Label>Company Website</Label>
                            <Input
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <Label>Company Address</Label>
                            <Input
                                value={companyAddress}
                                onChange={(e) => setCompanyAddress(e.target.value)}
                            />
                        </Grid>
                    </Card>
                    <Card item md={5.8}>
                        <CardHeading mb={1}>Company Information</CardHeading>
                        <Grid sx={{ display: "flex", flexDirection: "column" }}>
                            <Grid my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CalendarTodayOutlinedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={10} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px" }}>
                                    <Label>CR Number</Label>
                                    <Input
                                        value={crNumber}
                                        onChange={(e) => setCrNumber(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CalendarMonthOutlinedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={10} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "0px", }}>
                                    <Typography sx={{ fontSize: 12, fontWeight: "600" }}>Expiring Date</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]}>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                valueFormat="YYYY-MM-DD"
                                                value={dayjs(expiryDate)}
                                                onChange={(value) => setExpiryDate(value)}
                                                slotProps={{ textField: { size: "small", error: false } }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <TaskAltOutlinedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={10} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px", opacity: 0.4, }}>
                                    <Input
                                        disabled={true}
                                        label="CR documentation"
                                        value={companyName?.documentStatus ? "Validated" : "Not Validated"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <TaskAltOutlinedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={10} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px", opacity: 0.4 }}>
                                    <Input
                                        disabled={true}
                                        label="Saudi council of engineers certificate"
                                        value={companyName?.documentStatus ? "Validated" : "Not Validated"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid container my={3}>
                    <Card item md={12}>
                        <CardHeading mb={1}>Bank Account Information</CardHeading>
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                            <Grid item md={10} my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <AccountBalanceOutlinedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={9.7} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px", }}>
                                    <Label>Bank name</Label>
                                    <Input
                                        value={bankName}
                                        onChange={(e) => setBankName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item md={10} my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <WidgetsOutlinedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={10} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px", }}>
                                    <Label>IBAN number</Label>
                                    <Input
                                        value={bankIBAN}
                                        onChange={(e) => setBankIBAN(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                            <Grid item md={10} my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <MoneyIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={9.7} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px", }}>
                                    <Label>Branch code</Label>
                                    <Input
                                        value={bankBranchCode}
                                        onChange={(e) => setBankBranchCode(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item md={10} my={1} sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                                <Box
                                    sx={{
                                        width: "50px",
                                        height: "50px",
                                        backgroundColor: "#662D91",
                                        borderRadius: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <RoomRoundedIcon sx={{ color: "#ffff" }} />
                                </Box>
                                <Grid item md={10} sx={{ display: "flex", flexDirection: "column", ml: "20px", gap: "5px", }}>
                                    <Label>Bank address</Label>
                                    <Input
                                        value={bankAddress}
                                        onChange={(e) => setBankAddress(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Box display={"flex"} justifyContent={"flex-end"} gap={"20px"} paddingRight={"20px"} margin={"30px 0px"}>
                    <Button size="small" style={{ textTransform: "none", padding: "4px 40px" }} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button size="small" style={{ textTransform: "none", padding: "4px 40px" }} color="secondary" variant="contained"
                        onClick={() => updateUserData()}>
                        {uploading ? (
                            <>
                                <MoonLoader color="#fff" size={20} />
                            </>
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Box>
                <Card item md={12}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <CardHeading>Change Password</CardHeading>
                        <Box onClick={() => setChangePassword(!changePassword)}
                            sx={{
                                cursor: "pointer",
                                display: "flex",
                                height: 40,
                                width: 40,
                                borderRadius: 20,
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: changePassword ? "#d49e6e" : "#f5f5f5",
                            }}
                        >
                            <EditRounded sx={{ color: changePassword ? "#ffffff" : "#666" }} />
                        </Box>
                    </Box>
                    <Grid container my={3} justifyContent={"space-between"} style={{ opacity: changePassword ? 1 : 0.3 }}>
                        <Grid item md={3.8} sx={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                type={showCurrentPassword ? 'text' : 'password'}
                                disabled={!Boolean(changePassword)}
                                label={"Current password"}
                                value={passwordData.current}
                                onChange={(e) => { setPasswordData({ ...passwordData, current: e.target.value }) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={PasswordVisibility}
                                                edge="end"
                                            >
                                                {showCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={3.8} sx={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                type={showNewPassword ? 'text' : 'password'}
                                disabled={!Boolean(changePassword)}
                                label={"New password"}
                                value={passwordData.new}
                                onChange={(e) => { setPasswordData({ ...passwordData, new: e.target.value }) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={NewPasswordVisibility}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item md={3.8} sx={{ display: "flex", flexDirection: "column" }}>
                            <TextField
                                type={showConfirmPassword ? 'text' : 'password'}
                                disabled={!Boolean(changePassword)}
                                label={"Confirm password"}
                                value={passwordData.confirm}
                                onChange={(e) => { setPasswordData({ ...passwordData, confirm: e.target.value }) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={ConfirmPasswordVisibility}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    {changePassword && (
                        <Box display={"flex"} justifyContent={"flex-end"} gap={"20px"} paddingRight={"20px"} marginTop={"10px"}>
                            <Button size="small" style={{ textTransform: "none", padding: "4px 40px" }} color="secondary"
                                variant="contained" onClick={() => changeUserPassword()}
                                disabled={passwordData.new !== passwordData.confirm}
                            >
                                {updatingPass ? (
                                    <>
                                        <MoonLoader color="#fff" size={20} />
                                    </>
                                ) : (
                                    "Change Password"
                                )}
                            </Button>
                        </Box>
                    )}
                </Card>
            </Wrapper>
        </>
    );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
    width: "100%",
    height: "calc(100vh - 60px)",
    overflow: "auto",
    backgroundColor: "white",
    padding: "20px",
}))

const Card = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    padding: "15px 25px",
    borderRadius: "14px",
    border: "1px solid #ddd",
}))

const CardHeading = styled(Typography)(() => ({
    fontSize: "20px",
    fontWeight: "600",
}))

const Input = styled(TextField)({
    width: "100%",
    background: "#fff",
    borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
    fontSize: "12px",
    fontWeight: "500"
}))

export default Dashboard;
