import React, { useEffect, useState } from "react";

// MUI Components Import
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";

// Icons Import

// Loader Import
import { MoonLoader } from "react-spinners";

// React Toastify Imports
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../../../constants/api";

function Copyright() {
  const token = window.localStorage.getItem("user-token");

  const [saving, setSaving] = useState(false);
  const [poweredBy, setPoweredBy] = useState("");

  const fetchCopyrightData = () => {
    axios
      .get(`${baseUrl}footer/powered`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setPoweredBy(response.data.powered_by);
      })
      .catch(() => {});
  };

  const handleCopyright = () => {
    setSaving(true);
    const formData = new FormData();
    formData.append("powered_by", poweredBy);
    axios
      .put(`${baseUrl}footer/powered`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then(() => {
        toast.success("Partners added successfully!");
        setSaving(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
        setSaving(false);
      });
  };

  useEffect(() => {
    fetchCopyrightData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SectionWrapper mt={3}>
        <Heading>Copyright</Heading>
        <Grid
          container
          mt={1}
          spacing={3}
          display={"flex"}
          alignItems={"center"}
        >
          <Grid
            item
            md={12}
            display={"flex"}
            alignItems={"center"}
            gap={"15px"}
          >
            <Grid item md={3}>
              <Label>Powered by</Label>
              <Input
                mt={3}
                type="text"
                color="success"
                InputProps={{ sx: { borderRadius: 3 } }}
                value={poweredBy}
                onChange={(e) => setPoweredBy(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <SaveBTN onClick={() => handleCopyright()}>
              {saving ? (
                <>
                  <MoonLoader color="#fff" size={20} />
                </>
              ) : (
                "Save"
              )}
            </SaveBTN>
          </Grid>
        </Grid>
      </SectionWrapper>
    </>
  );
}

const SectionWrapper = styled(Box)(() => ({
  width: "100%",
  background: "#fff",
  padding: "10px 15px",
  borderRadius: "12px",
  fontSize: "22px",
  fontWeight: "600",
}));

const Heading = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "600",
  color: "#424242",
}));

const Input = styled(TextField)({
  width: "100%",
  background: "#fff",
  borderRadius: "5px",
});

const Label = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: "500",
}));

const SaveBTN = styled(Button)(({ theme }) => ({
  width: "120px",
  height: "40px",
  marginTop: "15px",
  fontSize: "16px",
  background: `${theme.palette.primary.main}`,
  color: "#fff",
  borderRadius: "5px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

export default Copyright;
