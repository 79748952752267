import { apiSlice } from "./apiSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ limit, offset, serviceScreen }) => {
        const url = serviceScreen
          ? `services?limit=${limit}&offset=${offset}`
          : `products?limit=${limit}&offset=${offset}`;
        return {
          url,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
      },
      providesTags: ["Products"],
    }),
    getSingleProductVariants: builder.query({
      query: (id) => {
        return {
          url: `product/variant/list/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Products"],
    }),
  }),
});

export const { useGetProductsQuery, useGetSingleProductVariantsQuery } =
  productsApiSlice;
