import React from "react";
import { styled } from "@mui/material/styles";

// MUI Components Import
import { Container } from "@mui/material";

// Components Import
import Heading from "../../components/Heading"
import TaxSelect from "./components/TaxSelect";
import DiscountOnFirstOrder from "./components/DiscountOnFirstOrder";
import MetaPixel from "./components/MetaPixel";
import Integrations from "./components/Integrations";
import GoogleScript from "./components/GoogleScript";

const headerData = {
  heading: "Configure your settings",
  subheading: "",
};

const Configurations = () => {
  return (
    <>
      <Wrapper maxWidth={false}>
        <Heading data={headerData} />
        <TaxSelect />
        <DiscountOnFirstOrder />
        <MetaPixel />
        <GoogleScript />
        <Integrations />
      </Wrapper>
    </>
  );
};

// Styled Components

const Wrapper = styled(Container)(() => ({
  width: "100%",
  padding: "1rem",
  height: "calc(100vh - 60px)",
  overflow: "auto",
}));

export default Configurations;
